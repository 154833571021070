import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { db } from "./Firebase/firebaseConfig";
import { Setting2, Notification } from "iconsax-react";
import { Avatar, Button } from "@nextui-org/react";
import UserContext from "../components/UserContext";
import SettingsModal from "./SettingsModal";
import Notifications from './Notifications';
import SearchBar from './SearchBar'; // Import du nouveau composant

const TopNavBar = () => {
    const { currentUser } = useContext(UserContext);
    const [showNotifications, setShowNotifications] = useState(false);
    const [unreadCount, setUnreadCount] = useState(0);
    const notificationsRef = useRef(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (currentUser) {
            const notificationsCollection = collection(db, "notifications");
            const q = query(notificationsCollection, where("recipientId", "==", currentUser.uid), where("read", "==", false));

            const unsubscribe = onSnapshot(q, (snapshot) => {
                setUnreadCount(snapshot.docs.length);
            });

            return () => unsubscribe();
        }
    }, [currentUser]);

    const toggleNotifications = () => {
        setShowNotifications(!showNotifications);
    };

    const handleSettingsModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const handleNavigate = (path) => {
        navigate(path);
    };

    return (
        <div className="relative flex flex-col mb-4 ">
            <div className='flex items-start gap-8'>
                <SearchBar />

                {currentUser ? (
                    <div className='flex flex-row items-center gap-4 '>
                        <div className='flex flex-row items-center '>
                            <div className="flex justify-center relative cursor-pointer w-[40px]" onClick={toggleNotifications}>
                                <div className={`notification-icon ${unreadCount > 0 ? 'notification-ring' : ''}`}>
                                    <Notification size={22} variant={unreadCount > 0 ? "Bold" : "Linear"} />
                                    {unreadCount > 0 && <div className="notification-badge" />}
                                </div>

                                {showNotifications && (
                                    <div ref={notificationsRef} className="shadow-2xl absolute top-full mt-2 right-0 z-50 w-64 lg:w-96">
                                        <Notifications />
                                    </div>
                                )}
                            </div>

                            {currentUser.uid && (
                                <Button isIconOnly variant="light" onClick={handleSettingsModal}>
                                    <Setting2 size={20} className="text-foreground" variant="Bold" />
                                </Button>
                            )}
                        </div>

                        <Avatar
                            src={currentUser.profilePicture}
                            className="text-tiny cursor-pointer"
                            onClick={() => handleNavigate(`/profile/${currentUser.username}`)}
                        />
                    </div>
                ) : (
                    <div className='flex flex-row items-center gap-4'>
                        <div className="cursor-pointer" onClick={() => handleNavigate('/login')}>
                            Login
                        </div>
                        <div className="cursor-pointer" onClick={() => handleNavigate('/signup')}>
                            Signup
                        </div>
                    </div>
                )}

                <SettingsModal isOpen={isModalOpen} onClose={handleSettingsModal} />
            </div>
        </div>
    );
};

export default TopNavBar;
