import React, { useContext, useState, useEffect, useCallback, useRef } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";

import { Helmet } from 'react-helmet';

import { Button, Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Tooltip, Avatar, Tab, Tabs, Divider, Textarea, Input, Skeleton, Spacer } from "@nextui-org/react";
import { collection, getDocs, query, where, addDoc, deleteDoc, doc, getDoc, updateDoc } from "firebase/firestore";
import { db, storage } from "./Firebase/firebaseConfig";
import { getDownloadURL, ref, uploadBytesResumable, deleteObject } from "firebase/storage";
import UserContext from "./UserContext";
import ImageCard from "./ImageCard";
import { ArrowDown2, Gallery, Heart, GalleryAdd, Archive, Edit2, ExportSquare, Rank } from 'iconsax-react';
import BadgeDisplay from './BadgeDisplay'; // Assurez-vous que le chemin est correct
import imageCompression from 'browser-image-compression';
import DescriptionObjectCard from "./DescriptionObjectCard";
import { useTranslation } from 'react-i18next';
import UserFollowStats from "./UserFollowStats";
import useUserRank from "./useUserRank";
import cosmicShardIcon from "../assets/images/gemstone.png"; // Import the image



const Profile = () => {
    const { username } = useParams();
    const { currentUser } = useContext(UserContext);
    const [profileUser, setProfileUser] = useState(null);
    const [userImages, setUserImages] = useState([]);
    const [likedImages, setLikedImages] = useState([]);
    const [targetItems, setTargetItems] = useState([]); // State for target items
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [followersCount, setFollowersCount] = useState(0);
    const [followingCount, setFollowingCount] = useState(0);
    const [sortOrder, setSortOrder] = useState("mostRecent");
    const [loading, setLoading] = useState(true);
    const [uploading, setUploading] = useState(false);
    const [userBadges, setUserBadges] = useState({});
    const [activeTab, setActiveTab] = useState("images"); // Nouvel état pour l'onglet actif
    const navigate = useNavigate();
    const overflowDivRef = useRef(null);
    const [isEditing, setIsEditing] = useState(false);
    const [description, setDescription] = useState(profileUser?.description || "");
    const { t } = useTranslation();
    const [isEditingWebsite, setIsEditingWebsite] = useState(false);
    const [website, setWebsite] = useState(profileUser?.website || "");

    const { userRank, cosmicShards, loading: userRankLoading } = useUserRank(profileUser?.id);


    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleEditWebsiteClick = () => {
        setIsEditingWebsite(true);
    };


    const handleSaveWebsiteClick = async () => {
        if (currentUser) {
            try {
                const userDocRef = doc(db, "users", currentUser.uid);
                await updateDoc(userDocRef, { website });
                setProfileUser(prevProfile => ({ ...prevProfile, website }));
                setIsEditingWebsite(false);
            } catch (error) {
                console.error("Erreur lors de la mise à jour du site Web :", error);
            }
        }
    };


    const handleCancelWebsiteClick = () => {
        setWebsite(profileUser?.website || "");
        setIsEditingWebsite(false);
    };

    const handleSaveClick = async () => {
        if (currentUser) {
            try {
                const userDocRef = doc(db, "users", currentUser.uid);
                await updateDoc(userDocRef, { description });
                setProfileUser(prevProfile => ({ ...prevProfile, description }));
                setIsEditing(false);
            } catch (error) {
                console.error("Error updating description:", error);
            }
        }
    };

    const handleCancelClick = () => {
        setDescription(profileUser?.description || "");
        setIsEditing(false);
    };


    const fetchUserImages = useCallback(async (userId) => {
        if (userId) {
            setLoading(true);
            const imagesCollection = collection(db, "images");
            let q;
            if (currentUser && currentUser.uid === userId) {
                q = query(imagesCollection, where("author", "==", userId));
            } else {
                q = query(
                    imagesCollection,
                    where("author", "==", userId),
                    where("publish", "==", true),
                    where("state", "==", true)
                );
            }
            const imagesSnapshot = await getDocs(q);
            const imagesList = imagesSnapshot.docs
                .map(doc => ({ id: doc.id, ...doc.data() }))
                .sort((a, b) => {
                    const aTimestamp = a.timestamp ? a.timestamp.seconds : 0;
                    const bTimestamp = b.timestamp ? b.timestamp.seconds : 0;
                    return sortOrder === "mostRecent" ? bTimestamp - aTimestamp : aTimestamp - bTimestamp;
                });
            setUserImages(imagesList);
            setLoading(false);
        }
    }, [sortOrder, currentUser]);

    const fetchLikedImages = useCallback(async (userId) => {
        if (userId) {
            setLoading(true);
            const likesCollection = collection(db, "likes");
            const q = query(likesCollection, where("userId", "==", userId));
            const likesSnapshot = await getDocs(q);
            const likedImageIds = likesSnapshot.docs.map(doc => doc.data().imageId);
            if (likedImageIds.length > 0) {
                const likedImagesList = [];
                for (const imageId of likedImageIds) {
                    const imageDoc = await getDoc(doc(db, "images", imageId));
                    if (imageDoc.exists()) {
                        likedImagesList.push({ id: imageDoc.id, ...imageDoc.data() });
                    }
                }
                setLikedImages(likedImagesList);
            } else {
                setLikedImages([]);
            }
            setLoading(false);
        }
    }, []);

    const fetchTargetItems = useCallback(async (userId) => {
        if (userId) {
            setLoading(true);
            try {
                const targetDocRef = doc(db, 'users', userId, 'cosmio', 'targets');
                const targetDoc = await getDoc(targetDocRef);
                const targetData = targetDoc.exists() ? targetDoc.data() : {};
                const targets = targetData?.targets || [];
                setTargetItems(targets);
            } catch (error) {
                console.error('Error fetching targets:', error);
            }
            setLoading(false);
        }
    }, []);

    const addToTarget = async (item) => {
        if (currentUser) {
            try {
                const targetDocRef = doc(db, 'users', currentUser.uid, 'cosmio', 'targets');
                const targetDoc = await getDoc(targetDocRef);
                const targetData = targetDoc.exists() ? targetDoc.data() : { targets: [] };
                const updatedTargets = [...targetData.targets, item];
                await updateDoc(targetDocRef, { targets: updatedTargets });
                setTargetItems(updatedTargets);
            } catch (error) {
                console.error('Error adding to targets:', error);
            }
        }
    };

    const removeFromTarget = async (item) => {
        if (currentUser) {
            try {
                const targetDocRef = doc(db, 'users', currentUser.uid, 'cosmio', 'targets');
                const targetDoc = await getDoc(targetDocRef);
                const targetData = targetDoc.exists() ? targetDoc.data() : { targets: [] };
                const updatedTargets = targetData.targets.filter(targetItem => targetItem !== item);
                await updateDoc(targetDocRef, { targets: updatedTargets });
                setTargetItems(updatedTargets);
            } catch (error) {
                console.error('Error removing from targets:', error);
            }
        }
    };

    const toggleTarget = (item) => {
        if (targetItems.includes(item)) {
            removeFromTarget(item);
        } else {
            addToTarget(item);
        }
    };

    const fetchBadges = useCallback(async (userId) => {
        const badgesDocRef = doc(db, `users/${userId}/cosmio/badges`);
        const badgesDoc = await getDoc(badgesDocRef);
        if (badgesDoc.exists()) {
            const badgesData = badgesDoc.data();
            const formattedBadges = {};

            // Formater les badges pour obtenir le niveau le plus élevé
            for (const [key, value] of Object.entries(badgesData)) {
                if (value) {
                    const [badge, level] = key.split('_');
                    if (!formattedBadges[badge] || formattedBadges[badge] < parseInt(level)) {
                        formattedBadges[badge] = parseInt(level);
                    }
                }
            }

            setUserBadges(formattedBadges);
        }
    }, []);

    const fetchProfileUser = useCallback(async () => {
        if (username) {
            setLoading(true);
            const usersCollection = collection(db, "users");
            const usersSnapshot = await getDocs(usersCollection);

            // Check if a document matches the username in a case-insensitive manner
            const matchingUserDoc = usersSnapshot.docs.find(doc => {
                const userData = doc.data();
                return userData.username && userData.username.toLowerCase() === username.toLowerCase();
            });

            if (matchingUserDoc) {
                const userId = matchingUserDoc.id;
                const userData = matchingUserDoc.data();
                const isPro = userData.isPro || false; // Check if the user has a pro account
                setProfileUser({ id: userId, ...userData, isPro });

                // Update the state with the retrieved website and description values
                setWebsite(userData.website || "");
                setDescription(userData.description || "");

                fetchUserImages(userId);
                fetchLikedImages(userId); // Fetch liked images
                checkSubscriptionStatus(userId);
                fetchFollowersCount(userId);
                fetchFollowingCount(userId);
                fetchTargetItems(userId); // Fetch target list items
                fetchBadges(userId);

                // Redirect to the proper URL with correct casing if necessary
                if (userData.username !== username) {
                    navigate(`/profile/${userData.username}`, { replace: true });
                }
            } else {
                setProfileUser(null);
            }
            setLoading(false);
        }
    }, [username, fetchUserImages, fetchLikedImages, fetchTargetItems, fetchBadges, navigate]);


    const checkSubscriptionStatus = useCallback(async (userId) => {
        if (currentUser) {
            const subscriptionsCollection = collection(db, "subscriptions");
            const q = query(subscriptionsCollection, where("subscriberId", "==", currentUser.uid), where("subscribedToId", "==", userId));
            const subscriptionSnapshot = await getDocs(q);
            setIsSubscribed(!subscriptionSnapshot.empty);
        }
    }, [currentUser]);

    const fetchFollowersCount = useCallback(async (userId) => {
        const subscriptionsCollection = collection(db, "subscriptions");
        const q = query(subscriptionsCollection, where("subscribedToId", "==", userId));
        const subscriptionSnapshot = await getDocs(q);
        setFollowersCount(subscriptionSnapshot.size);
    }, []);

    const fetchFollowingCount = useCallback(async (userId) => {
        const subscriptionsCollection = collection(db, "subscriptions");
        const q = query(subscriptionsCollection, where("subscriberId", "==", userId));
        const subscriptionSnapshot = await getDocs(q);
        setFollowingCount(subscriptionSnapshot.size);
    }, []);

    useEffect(() => {
        fetchProfileUser();
        if (overflowDivRef.current) {
            overflowDivRef.current.scrollTop = 0;
            console.log('restScrol')
        }
    }, [fetchProfileUser]);

    useEffect(() => {
        if (profileUser && currentUser) {
            checkSubscriptionStatus(profileUser.id);
        }
    }, [profileUser, currentUser, checkSubscriptionStatus]);


    const handleLoginSuccess = () => {
        fetchProfileUser(); // Refetch profile data after successful login
    };

    const handleSubscribe = async () => {
        if (currentUser && profileUser) {
            const subscriptionsCollection = collection(db, "subscriptions");
            await addDoc(subscriptionsCollection, {
                subscriberId: currentUser.uid,
                subscribedToId: profileUser.id,
            });
            setIsSubscribed(true);
            setFollowersCount(prevCount => prevCount + 1);
        }
    };

    const handleUnsubscribe = async () => {
        if (currentUser && profileUser) {
            const subscriptionsCollection = collection(db, "subscriptions");
            const q = query(subscriptionsCollection, where("subscriberId", "==", currentUser.uid), where("subscribedToId", "==", profileUser.id));
            const subscriptionSnapshot = await getDocs(q);
            if (!subscriptionSnapshot.empty) {
                const subscriptionDoc = subscriptionSnapshot.docs[0];
                await deleteDoc(doc(db, "subscriptions", subscriptionDoc.id));
                setIsSubscribed(false);
                setFollowersCount(prevCount => prevCount - 1);
            }
        }
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file && currentUser) {
            // Check if the file is an image
            if (!file.type.startsWith('image/')) {
                alert("Please select an image file.");
                return;
            }

            // Check if the file size exceeds 1 MB
            if (file.size > 1 * 1024 * 1024) { // 1 MB = 1 * 1024 * 1024 bytes
                alert("The file size exceeds 1 MB. Please choose a smaller image.");
                return;
            }

            try {
                setUploading(true);

                // Get the user document
                const userDocRef = doc(db, "users", currentUser.uid);
                const userDoc = await getDoc(userDocRef);

                // Delete the old profile picture if it exists
                if (userDoc.exists() && userDoc.data().profilePicture) {
                    const oldProfilePicRef = ref(storage, userDoc.data().profilePicture);
                    await deleteObject(oldProfilePicRef).catch((error) => {
                        console.error("Error deleting old profile picture:", error);
                    });
                }

                // Compression options
                const options = {
                    maxSizeMB: 1,
                    maxWidthOrHeight: 400,
                    useWebWorker: true
                };
                const compressedFile = await imageCompression(file, options);

                // Upload the new image file
                const storageRef = ref(storage, `profilePictures/${currentUser.uid}`);
                const uploadTask = uploadBytesResumable(storageRef, compressedFile);

                uploadTask.on('state_changed',
                    (snapshot) => {
                        // Optional: Handle progress
                    },
                    (error) => {
                        console.error("Upload failed:", error);
                        setUploading(false);
                    },
                    async () => {
                        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                        await updateDoc(userDocRef, { profilePicture: downloadURL });
                        setProfileUser(prevProfile => ({
                            ...prevProfile,
                            profilePicture: downloadURL
                        }));
                        setUploading(false);
                    }
                );
            } catch (error) {
                console.error("Error while processing image:", error);
                setUploading(false);
            }
        }
    };

    if (loading) {
        return (
            <div className="min-h-screen flex flex-col gap-6" >

                <div className="flex flex-row gap-6 items-center" >
                    <Skeleton className="w-20 h-20 md:w-28 md:h-28 rounded-full bg-default-100">
                    </Skeleton>
                    <Skeleton className="w-60 h-10 rounded-lg bg-default-100">
                    </Skeleton>
                </div>


                <div className="flex flex-col gap-2" >
                    <Skeleton className="w-52 h-5 rounded-lg bg-default-100">
                    </Skeleton>
                    <Skeleton className="w-40 h-5 rounded-lg bg-default-100">
                    </Skeleton>
                </div>

                <Spacer />
                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-4 gap-4">
                    {Array.from({ length: 6 }).map(() => (
                        <Skeleton className="rounded-xl bg-default-100">
                            <div className="h-[342px] "></div>
                        </Skeleton>
                    ))}
                </div>
            </div>
        );
    }


    return (
        <div className="overflow-auto w-full min-h-screen ">

            {profileUser ? (
                <div className="flex flex-col"  >

                    <div className="relative flex flex-col gap-4 justify-start  mb-4">
                        <div className=" flex flex-row md:gap-6  justify-between md:justify-start items-center">

                            <label htmlFor="profile-picture-upload">
                                <Tooltip
                                    isDisabled={!currentUser || currentUser.uid !== profileUser.id}
                                    className="dark"
                                    content={t('uploadprofilepicture')}
                                >
                                    <Avatar
                                        src={profileUser.profilePicture}
                                        className="w-20 h-20 md:w-28 md:h-28 text-tiny cursor-pointer border border-stone-800"
                                    />
                                </Tooltip>


                            </label>

                            {currentUser && currentUser.uid === profileUser.id && (


                                <>
                                    <input
                                        id="profile-picture-upload"
                                        type="file"
                                        onChange={handleFileChange}
                                        disabled={uploading}
                                        style={{ display: 'none' }}
                                    />
                                    {uploading && <p className="text-sm text-default-500">Uploading...</p>}
                                </>
                            )}


                            <UserFollowStats
                                userId={profileUser.id}
                                db={db}
                                t={t}
                                followersCount={followersCount}
                                followingCount={followingCount}
                            />



                            <BadgeDisplay profileUser={profileUser} badges={userBadges} />
                        </div>

                        <div className="flex flex-col gap-2" >

                            <div className="flex items-center gap-2" >
                                <p className="font-medium text-lg" >{profileUser.username}</p>


                                {cosmicShards && (
                                    <Link to="/ranking" className="flex hover:bg-default-50 p-2 py-1 rounded-md flex gap-3 items-center">

                                        <div className="flex" >
                                            <Rank size={20} variant="Bold" />
                                            <p  >#{userRank}</p>
                                        </div>


                                        <div className="flex items-center gap-1 ">
                                            <img src={cosmicShardIcon} alt="Cosmic Shard Icon" className="w-5" />
                                            <div>
                                                <p className="text-primary-50">{cosmicShards.toFixed(0)}</p>
                                            </div>
                                        </div>

                                    </Link>
                                )}

                                {profileUser?.isPro && (
                                    <div >
                                        {isEditingWebsite ? (
                                            <div className="flex max-w-screen-sm gap-2">
                                                <Input
                                                    type="text"
                                                    value={website}
                                                    onChange={(e) => setWebsite(e.target.value)}
                                                    placeholder="Enter your website URL"
                                                    variant="underlined"
                                                    defaultValue={website}
                                                />
                                                <div className="flex gap-2 mt-2">
                                                    <Button size="sm" onPress={handleCancelWebsiteClick}>
                                                        Cancel
                                                    </Button>
                                                    <Button color="primary" size="sm" onPress={handleSaveWebsiteClick}>
                                                        Save
                                                    </Button>
                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                                <Button
                                                    startContent={<ExportSquare size={16} />}
                                                    size="sm"
                                                    onPress={() => {
                                                        let userWebsite = profileUser?.website;

                                                        if (userWebsite) {
                                                            // Ajouter automatiquement http:// si l'URL ne commence pas par http:// ou https://
                                                            if (!/^https?:\/\//i.test(userWebsite)) {
                                                                userWebsite = 'http://' + userWebsite;
                                                            }
                                                            window.open(userWebsite, "_blank");
                                                        } else {
                                                            alert("No website available");
                                                        }
                                                    }}
                                                >
                                                    Visit the website
                                                </Button>

                                                {currentUser && currentUser.uid === profileUser.id && !isEditing && (


                                                    <Button size="sm" isIconOnly variant="light" onPress={handleEditWebsiteClick}>
                                                        <Edit2 variant="Bold" size={18} />
                                                    </Button>
                                                )}


                                            </>
                                        )}
                                    </div>
                                )}
                            </div>


                            {isEditing ? (
                                <div>
                                    <Textarea
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                        rows={4}
                                        className="w-full mb-2"
                                        variant="underlined"
                                        label="Description"

                                    />
                                    <div className="w-full flex space-x-2 justify-end">

                                        <Button onPress={handleCancelClick} size="sm">
                                            Cancel
                                        </Button>
                                        <Button color="primary" onPress={handleSaveClick} size="sm">
                                            Save
                                        </Button>
                                    </div>
                                </div>
                            ) : (
                                <div className="flex" >
                                    {profileUser.description && (
                                        <div className="flex items-center ">
                                            <p className="text-sm text-default-500 max-w-2xl">{profileUser.description}</p>
                                        </div>
                                    )}

                                    {currentUser && currentUser.uid === profileUser.id && !isEditing && (

                                        <Button variant="light" isIconOnly onPress={handleEditClick} className=" h-6" size="sm">
                                            <Edit2 size={18} variant="Bold" />
                                        </Button>
                                    )}
                                </div>
                            )}

                        </div>




                        {currentUser && currentUser.uid !== profileUser.id && (


                            <div className="flex flex-col" >

                                {isSubscribed ? (
                                    <Button size="sm" variant="flat" onPress={handleUnsubscribe}>{t('unfollow')}</Button>
                                ) : (
                                    <Button color="primary" size="sm" onPress={handleSubscribe}>{t('follow')}</Button>
                                )}
                            </div>
                        )}

                    </div>
                    {currentUser && currentUser.uid === profileUser.id && (

                        <Divider />
                    )}
                    <div className="flex justify-between  items-center ">
                        {currentUser && currentUser.uid === profileUser.id && (

                            <>

                                <Tabs size="md" variant="underlined" className="my-2" onSelectionChange={setActiveTab}>
                                    <Tab key="images"
                                        title={
                                            <div className="flex items-center space-x-1">
                                                <Gallery size={18} variant="Bold" />

                                            </div>
                                        }
                                    ></Tab>

                                    <Tab key="liked"
                                        title={
                                            <div className="flex items-center space-x-1">
                                                <Heart size={18} variant="Bold" />

                                            </div>
                                        }
                                    ></Tab>
                                    <Tab key="target"
                                        title={
                                            <div className="flex items-center space-x-1">
                                                <Archive size={18} variant="Bold" />

                                            </div>
                                        }
                                    ></Tab>
                                </Tabs>

                                <Dropdown className="dark">
                                    <DropdownTrigger  >
                                        <Button size="sm" variant="flat" >
                                            {sortOrder === "mostRecent" ? t('mostrecent') : t('oldestfirst')}
                                            <ArrowDown2 size={16} />
                                        </Button>
                                    </DropdownTrigger>
                                    <DropdownMenu aria-label="Dropdown menu with shortcut" onAction={(key) => setSortOrder(key)}>
                                        <DropdownItem key="mostRecent">  {t('mostrecent')}</DropdownItem>
                                        <DropdownItem key="oldestFirst">  {t('oldestfirst')}</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </>

                        )}
                    </div>

                    {activeTab === "images" && (
                        <>
                            {userImages.length === 0 && !loading && (
                                <div className="flex flex-col items-center justify-center mt-4 gap-4">
                                    <GalleryAdd size="70" variant="Linear" color="hsl(var(--nextui-default-400))" />
                                    <p className="text-md text-default-500 text-center "> Your profile is empty. <Link href="#" className="underline" to="/publish" >Add photo now!</Link></p>
                                </div>
                            )}

                            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                                {userImages.map((image, index) => (
                                    <ImageCard key={index} image={image} />
                                ))}
                            </div>
                        </>
                    )}

                    {activeTab === "liked" && (
                        <>
                            {likedImages.length === 0 && !loading && (
                                <div className="flex flex-col items-center justify-center mt-4 gap-6">
                                    <p className="text-md text-default-400 text-center "> No liked photos. </p>
                                </div>
                            )}

                            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                                {likedImages.map((image, index) => (
                                    <ImageCard key={index} image={image} />
                                ))}
                            </div>
                        </>
                    )}

                    {activeTab === "target" && (
                        <>
                            {targetItems.length === 0 && !loading && (
                                <div className="flex flex-col items-center justify-center mt-4 gap-6">
                                    <p className="text-md text-default-400 text-center ">No target items.</p>
                                </div>
                            )}

                            <div className="grid  md:grid-cols-2 grid-cols-1 flex flex-col gap-2">
                                {targetItems.map((item) => (
                                    <DescriptionObjectCard
                                        activeTab={activeTab}
                                        userId={currentUser.uid}
                                        key={item}
                                        tagsParam={item}
                                        isInTarget={targetItems.includes(item)}
                                        onTargetToggle={() => toggleTarget(item)}
                                    />
                                ))}
                            </div>
                        </>
                    )}
                </div>
            ) : (
                <p></p>
            )}



        </div >
    );
};

export default Profile;
