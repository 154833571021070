import React, { useState, useEffect, useRef } from 'react';
import { collection, getDocs, query, orderBy, where } from 'firebase/firestore';
import { db } from "./Firebase/firebaseConfig";
import { Button, RadioGroup, Radio, Input, Checkbox, Divider, Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, ScrollShadow, Modal, ModalBody, ModalHeader, ModalFooter } from "@nextui-org/react";
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import Logo from '../assets/images/logo.svg';
import { useTranslation } from 'react-i18next';
import { ArrowDown2, Add } from 'iconsax-react';

const { GoogleGenerativeAI } = require('@google/generative-ai');

const FullscreenSlideshow = () => {
    const [images, setImages] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [title, setTitle] = useState('');
    const [nextTitle, setNextTitle] = useState('');
    const [description, setDescription] = useState('');
    const [nextDescription, setNextDescription] = useState('');
    const [isExperienceActive, setIsExperienceActive] = useState(false); // Contrôle si l'expérience est active ou non
    const [transitionStyle, setTransitionStyle] = useState('fade');
    const [durationInSeconds, setDurationInSeconds] = useState(10);
    const [browserLanguage, setBrowserLanguage] = useState('en');
    const [imageFit, setImageFit] = useState('cover');
    const [shouldGenerateDescription, setShouldGenerateDescription] = useState(true);
    const [isChildMode, setIsChildMode] = useState(false);
    const [textPosition, setTextPosition] = useState('bottom-left');
    const [caption, setCaption] = useState('');
    const [nextCaption, setNextCaption] = useState('');
    const { t } = useTranslation();
    const [selectedImage, setSelectedImage] = useState(null); // Image sélectionnée
    const [isLoading, setIsLoading] = useState(false); // État de chargement

    const [displayMode, setDisplayMode] = useState('slideshow'); // Nouvel état pour choisir le mode d'affichage
    const slideshowRef = useRef(null);
    const genAI = new GoogleGenerativeAI('AIzaSyDZtKO5iGJSusGELWw9-whiRxkCRv4B1IU');
    const model = genAI.getGenerativeModel({ model: "gemini-1.5-flash" });

    useEffect(() => {
        const detectedLanguage = navigator.language || 'en';
        setBrowserLanguage(detectedLanguage.split('-')[0]);
    }, []);

    const fetchDescriptionForImage = async (objects, isNext = false) => {
        try {
            const objectsList = objects.join(', ');
            let prompt = isChildMode
                ? `Generate a simple and fun title and description for kids about the celestial object(s): ${objectsList}. Use simple language and respond in ${browserLanguage}. Please provide a title and a brief description in JSON format.`
                : `Generate a description and short title for the main celestial object(s): ${objectsList}. Please provide a title and a brief description (max 80 words) in JSON format. Respond in ${browserLanguage}.`;

            const result = await model.generateContent(prompt);
            const responseText = await result.response.text();
            const cleanedResponse = responseText.replace(/```json|```/g, '').trim();
            const fetchedData = JSON.parse(cleanedResponse);

            if (isNext) {
                setNextTitle(fetchedData.title);
                setNextDescription(fetchedData.description);
            } else {
                setTitle(fetchedData.title);
                setDescription(fetchedData.description);
            }
        } catch (error) {
            console.error("Error fetching description for image:", error);
        }
    };

    const getTimeAgo = (timestamp) => {
        const diffInMs = Date.now() - (timestamp?.seconds * 1000);

        const units = [
            { label: 'an', millis: 31536000000 },
            { label: 'mois', millis: 2592000000 },
            { label: 'jour', millis: 86400000 },
            { label: 'heure', millis: 3600000 },
            { label: 'minute', millis: 60000 },
        ];

        for (const { label, millis } of units) {
            const diff = Math.floor(diffInMs / millis);
            if (diff > 0) return `${diff} ${label}${diff > 1 && label !== 'mois' ? 's' : ''}`;
        }

        return 'moins d\'une minute';
    };

    const fetchCaption = async (imageMetadata, isNext = false) => {
        try {
            const { username, timestamp, metadata } = imageMetadata;
            const location = metadata?.LocationName || 'unknown location';
            const timeAgo = getTimeAgo(timestamp);

            const prompt = `
                Generate a simple sentence that says this image was taken by ${username} ${timeAgo} ago at ${location}.
                Respond with a single 'caption' field in JSON format. Respond in ${browserLanguage}.
            `;

            const result = await model.generateContent(prompt);
            const responseText = await result.response.text();
            const cleanedResponse = responseText.replace(/```json|```/g, '').trim();
            const fetchedData = JSON.parse(cleanedResponse);

            if (isNext) {
                setNextCaption(fetchedData.caption);
            } else {
                setCaption(fetchedData.caption);
            }
        } catch (error) {
            console.error("Error fetching caption for image:", error);
        }
    };

    const preLoadImage = (imageUrl) => {
        return new Promise((resolve) => {
            const img = new Image();
            img.src = imageUrl;
            img.onload = resolve;
        });
    };

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const imagesCollection = collection(db, 'images');
                const imagesSnapshot = await getDocs(
                    query(
                        imagesCollection,
                        where('like', '>=', 5),
                        orderBy('like', 'desc')
                    )
                );
                const imagesList = imagesSnapshot.docs
                    .map(doc => ({ ...doc.data(), objects: doc.data().objects || [] }))
                    .filter(image => image.objects && image.objects.length > 0);
                setImages(imagesList);
            } catch (error) {
                console.error('Erreur lors de la récupération des images :', error);
            }
        };

        fetchImages();
    }, []);

    useEffect(() => {
        if (isExperienceActive && displayMode === 'slideshow') {
            const preloadNextSlide = async () => {
                const nextIndex = currentIndex === images.length - 1 ? 0 : currentIndex + 1;

                if (images[nextIndex]?.imageUrl) {
                    await preLoadImage(images[nextIndex].imageUrl);
                    await fetchDescriptionForImage(images[nextIndex].objects, true);
                    await fetchCaption(images[nextIndex], true);
                }
            };

            preloadNextSlide();
        }
    }, [currentIndex, images, isExperienceActive]);

    useEffect(() => {
        if (isExperienceActive && displayMode === 'slideshow') {
            const interval = setInterval(() => {
                const nextIndex = currentIndex === images.length - 1 ? 0 : currentIndex + 1;
                setTitle(nextTitle);
                setDescription(nextDescription);
                setCaption(nextCaption);
                setCurrentIndex(nextIndex);
            }, durationInSeconds * 1000);

            return () => clearInterval(interval);
        }
    }, [nextTitle, nextDescription, nextCaption, isExperienceActive, currentIndex, durationInSeconds]);

    const startExperience = () => {
        setIsExperienceActive(true);
        if (displayMode === 'slideshow' && slideshowRef.current.requestFullscreen) {
            slideshowRef.current.requestFullscreen();
        }
    };

    const handleClose = () => {
        setIsExperienceActive(false);
        if (document.fullscreenElement) {
            document.exitFullscreen();
        }
    };

    const getTextPositionClass = () => {
        switch (textPosition) {
            case 'top-left':
                return 'top-4 left-4';
            case 'bottom-left':
                return 'bottom-4 left-4';
            default:
                return 'bottom-4 left-4';
        }
    };

    const handleImageClick = async (image) => {
        setIsLoading(true); // Commence à charger
        setSelectedImage(image); // Met à jour l'image sélectionnée

        try {
            // Appel à fetchDescriptionForImage pour générer la description
            await fetchDescriptionForImage(image.objects);

            // Appel à fetchCaption pour générer la légende
            await fetchCaption(image);
        } catch (error) {
            console.error("Erreur lors de la génération du texte :", error);
        }

        setIsLoading(false); // Fin du chargement
    };

    // Fonction pour rendre la mosaïque
    const renderMosaic = () => {
        return (
            <div className="columns-2 sm:columns-3 lg:columns-4 gap-2 overflow-auto">
                {images.map((image, index) => (
                    <div
                        key={index}
                        className="mb-2 break-inside-avoid hover:scale-105 ease-in-out duration-300 overflow-hidden rounded-md border border-stone-900"
                        onClick={() => handleImageClick(image)} // Ajouter l'événement de clic
                    >
                        <img
                            src={image.imageUrl}
                            alt={image.name}
                            className="w-full h-auto object-cover"
                            loading="lazy"
                        />
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div ref={slideshowRef} className="fixed inset-0 flex items-center justify-center z-50">
            <img onClick={handleClose} className='transition duration-150 top-4 left-4 absolute w-6 z-50 hover:opacity-100 opacity-40 cursor-pointer' src={Logo} alt="Logo" />
            {!isExperienceActive ? (
                <div className='flex flex-col gap-6 w-full lg:w-1/2 md:w-10/12 bg-default-50 p-8 rounded-lg'>
                    <div className='flex flex-col gap-2'>
                        <h1 className="text-3xl font-bold">{t('exhibitionMode')}</h1>
                        <p className="text-lg text-default-600">{t('exhibitionDescription')}</p>
                    </div>

                    <Divider />

                    <div className='flex flex-col gap-8'>
                        <h1 className="text-xl font-bold">{t('configuration')}</h1>

                        <div className='flex gap-12 items-end' >
                            <div className='flex flex-col gap-2' >

                                <label className='text-md text-default-400' >{t('selectImageFit')}</label>
                                <Dropdown className='dark' >
                                    <DropdownTrigger>
                                        <Button className='w-fit' variant='bordered' >

                                            {displayMode === "slideshow" ? 'Slideshow Mode' : 'Touchscreen Mode'}
                                            <ArrowDown2 size={18} />
                                        </Button>
                                    </DropdownTrigger>
                                    <DropdownMenu
                                        aria-label="Select Display Mode"
                                        selectionMode="single"
                                        selectedKeys={new Set([displayMode])}
                                        onSelectionChange={(key) => setDisplayMode(Array.from(key)[0])}
                                    >
                                        <DropdownItem key="slideshow">Slideshow Mode</DropdownItem>
                                        <DropdownItem key="mosaic">Touchscreen Mode</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>

                            <div>
                                <Checkbox isSelected={isChildMode} onValueChange={setIsChildMode}>
                                    {t('childMode')}
                                </Checkbox>
                                <p className='text-default-500'>{t('childModeDescription')}</p>
                            </div>

                        </div>




                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">


                            {displayMode === 'slideshow' && (
                                <>

                                    <RadioGroup
                                        label={'Select the image layout'}
                                        value={imageFit}
                                        onValueChange={setImageFit}
                                    >
                                        <Radio value="cover">{t('cover')}</Radio>
                                        <Radio value="contain">{t('contain')}</Radio>
                                    </RadioGroup>

                                    <RadioGroup
                                        label={t('selectTextPosition')}
                                        value={textPosition}
                                        onValueChange={setTextPosition}
                                    >
                                        <Radio value="top-left">{t('topLeft')}</Radio>
                                        <Radio value="bottom-left">{t('bottomLeft')}</Radio>
                                    </RadioGroup>

                                    <Input
                                        label={t('displayDuration')}
                                        type="number"
                                        labelPlacement='outside'
                                        value={durationInSeconds}
                                        onChange={(e) => setDurationInSeconds(Number(e.target.value))}
                                        min={1}
                                        endContent={
                                            <div className="pointer-events-none flex items-center">
                                                <span className="text-default-400 text-small">{t('seconds')}</span>
                                            </div>
                                        }
                                        className='max-w-40'
                                    />
                                </>
                            )}
                        </div>
                    </div>

                    <Divider />

                    <Button color='primary' className='w-full' size='lg' onPress={startExperience}>
                        {t('launchExperience')}
                    </Button>
                </div>
            ) : (
                // Affichage de l'expérience selon le mode choisi
                displayMode === 'slideshow' ? (
                    <div className="relative w-full h-full flex items-center justify-center">
                        {images.length > 0 && (
                            <SwitchTransition>
                                <CSSTransition
                                    key={currentIndex}
                                    timeout={1000}
                                    classNames="fade"
                                >
                                    <div className="w-full h-full relative">
                                        <img
                                            src={images[currentIndex].imageUrl}
                                            alt={`Slide ${currentIndex + 1}`}
                                            className={`w-full h-full slow-zoom ${imageFit === 'cover' ? 'object-cover' : 'object-contain'}`}
                                        />

                                        {shouldGenerateDescription && (title || description || caption) && (
                                            <div className={`absolute p-6 text-white flex flex-col gap-2 w-full ${getTextPositionClass()}`}>
                                                <h1 className="text-5xl font-bold mb-2 w-9/12">{title}</h1>
                                                <div className='flex flex-col gap-2 w-7/12'>
                                                    <p className='text-2xl'>{description}</p>
                                                    <p className='text-lg text-default-600'>{caption}</p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </CSSTransition>
                            </SwitchTransition>
                        )}
                    </div>
                ) : (
                    <>
                        <ScrollShadow className="overflow-auto  h-full">
                            {renderMosaic()}
                        </ScrollShadow>

                        {selectedImage && (
                            <div className="fixed inset-0 w-full h-full bg-black flex flex-col justify-center items-center z-50">

                                <div className="flex-1 w-full h-full flex items-center justify-center">
                                    <img
                                        src={selectedImage.imageUrl}
                                        alt={selectedImage.name}
                                        className="w-auto h-full object-contain"
                                    />
                                </div>

                                <div className='w-7/12 absolute left-4 bottom-4' >
                                    {isLoading ? (
                                        <></>
                                    ) : (
                                        <>
                                            <p className=" text-lg ">{description}</p>
                                            <p className='text-md text-default-600'>{caption}</p>
                                        </>
                                    )}

                                </div>



                                <Button isIconOnly variant='Light' className='absolute top-2 right-2' onClick={() => setSelectedImage(null)}>
                                    <Add size={50} className='rotate-45' />
                                </Button>

                            </div>
                        )}


                    </>
                )
            )}
        </div>
    );
};

export default FullscreenSlideshow;
