import React, { useEffect, useState, useCallback, useRef } from "react";
import { collection, getDocs, query, orderBy, where, limit as firebaseLimit, startAfter } from "firebase/firestore";
import { db } from "../components/Firebase/firebaseConfig";
import ImageCard from './ImageCard';
import { Skeleton } from "@nextui-org/react";
import { useTranslation } from 'react-i18next';

function Feed({ overflowDivRef }) {
    const [images, setImages] = useState([]);
    const [lastDoc, setLastDoc] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [hasMore, setHasMore] = useState(false);
    const [selectedObject, setSelectedObject] = useState(() => localStorage.getItem('selectedObject') || null); // Load from localStorage
    const loadMoreRef = useRef(null);
    const IMAGES_PER_PAGE = 20;
    const { t } = useTranslation();
    const planetObjects = ["Jupiter", "Mars", "Saturn", "Sun", "Moon", "Uranus", "Venus", "Mercury"];

    const fetchImages = useCallback(async (loadMore = false) => {
        setIsLoading(true);
        try {
            const imagesCollection = collection(db, "images");
            let imagesQuery = query(
                imagesCollection,
                where("state", "==", true),
                where("publish", "==", true),
                orderBy("timestamp", "desc"),
                firebaseLimit(IMAGES_PER_PAGE)
            );

            if (loadMore && lastDoc) {
                imagesQuery = query(
                    imagesCollection,
                    where("state", "==", true),
                    where("publish", "==", true),
                    orderBy("timestamp", "desc"),
                    startAfter(lastDoc),
                    firebaseLimit(IMAGES_PER_PAGE)
                );
            }

            const imagesSnapshot = await getDocs(imagesQuery);
            let imagesList = imagesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            if (selectedObject === "Planet") {
                imagesList = imagesList.filter(image =>
                    image.objects.some(obj => planetObjects.includes(obj))
                );
            } else if (selectedObject === "Deep Sky") {
                imagesList = imagesList.filter(image =>
                    !image.objects.some(obj => planetObjects.includes(obj))
                );
            }

            if (imagesSnapshot.docs.length < IMAGES_PER_PAGE) {
                setHasMore(false);
            }

            setImages(prevImages => loadMore ? [...prevImages, ...imagesList] : imagesList);

            if (imagesSnapshot.docs.length > 0) {
                setLastDoc(imagesSnapshot.docs[imagesSnapshot.docs.length - 1]);
                setHasMore(true);
            } else {
                setHasMore(false);
            }
        } catch (error) {
            console.error("Error fetching images:", error);
        } finally {
            setIsLoading(false);
        }
    }, [lastDoc, selectedObject]);

    useEffect(() => {
        fetchImages();
    }, [selectedObject]);

    const loadMoreImages = () => {
        if (!isLoading && hasMore) {
            fetchImages(true);
        }
    };

    const toggleObjectFilter = (object) => {
        const newObject = selectedObject === object ? null : object;
        setSelectedObject(newObject);
        setLastDoc(null);
        localStorage.setItem('selectedObject', newObject); // Save to localStorage
    };

    useEffect(() => {
        if (loadMoreRef.current) {
            const observer = new IntersectionObserver(
                (entries) => {
                    if (entries[0].isIntersecting && hasMore && !isLoading) {
                        loadMoreImages();
                    }
                },
                { threshold: 1.0 }
            );

            observer.observe(loadMoreRef.current);

            return () => observer.disconnect();
        }
    }, [loadMoreRef, hasMore, isLoading]);



    if (isLoading && images.length === 0) {
        return (
            <div>
                <div className="flex gap-2" >
                    {Array.from({ length: 3 }).map(() => (
                        <Skeleton className="mt-2 bg-default-100 h-7 rounded-lg w-20"></Skeleton>
                    ))}
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-4 gap-4">
                    {Array.from({ length: 6 }).map(() => (
                        <Skeleton className="rounded-xl bg-default-100">
                            <div className="h-[342px] "></div>
                        </Skeleton>
                    ))}
                </div>

            </div>
        );
    }



    return (
        <div>
            <div className="flex gap-2 my-2">
                <div
                    onClick={() => toggleObjectFilter(null)}
                    className={`font-medium flex flex-row gap-1 cursor-pointer px-4 py-1 rounded-lg text-sm  flex items-center ${selectedObject === null ? "bg-primary-400 text-white" : "bg-default-100 text-default-800"}`}
                >
                    {t("all")}
                </div>

                <div
                    onClick={() => toggleObjectFilter("Deep Sky")}
                    className={`font-medium flex flex-row gap-1 cursor-pointer px-4 py-1 rounded-lg text-sm  flex items-center ${selectedObject === "Deep Sky" ? "bg-primary-400 text-white" : "bg-default-100 text-default-800"}`}
                >
                    {t("deepsky")}
                </div>

                <div
                    onClick={() => toggleObjectFilter("Planet")}
                    className={`font-medium flex flex-row gap-1 cursor-pointer px-4 py-1 rounded-lg text-sm  flex items-center ${selectedObject === "Planet" ? "bg-primary-400 text-white" : "bg-default-100 text-default-800"}`}
                >
                    {t("planetary")}
                </div>

            </div>

            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-4 gap-4">
                {images.map((image) => (
                    <ImageCard key={image.id} image={image} overflowDivRef={overflowDivRef} />
                ))}
            </div>

            <div ref={loadMoreRef} >
            </div>
        </div>
    );
}

export default Feed;
