import React, { useState, useContext } from 'react';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { Modal, ModalContent, ModalHeader, ModalBody, Link, ModalFooter, User } from "@nextui-org/react";
import UserContext from './UserContext';

const UserFollowStats = ({
    userId,
    db,
    t,
    followersCount,
    followingCount,
}) => {
    const { currentUser } = useContext(UserContext);
    const [followersList, setFollowersList] = useState([]);
    const [followingList, setFollowingList] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");

    const handleFollowersClick = () => {
        setModalTitle(t('followers'));
        setIsModalOpen(true);
        fetchFollowersList(userId); // Call to fetch list on click
    };

    const handleFollowingClick = () => {
        setModalTitle(t('following'));
        setIsModalOpen(true);
        fetchFollowingList(userId); // Call to fetch list on click
    };

    const fetchFollowersList = async (userId) => {
        const subscriptionsCollection = collection(db, "subscriptions");
        const q = query(subscriptionsCollection, where("subscribedToId", "==", userId));
        const subscriptionSnapshot = await getDocs(q);

        // Récupérer les UIDs des abonnés
        const followerUids = subscriptionSnapshot.docs.map(doc => doc.data().subscriberId);

        // Rechercher les données des abonnés par leurs UIDs
        const followers = await Promise.all(
            followerUids.map(async (uid) => {
                const userDocRef = doc(db, "users", uid);
                const userDoc = await getDoc(userDocRef);
                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    return {
                        uid,
                        username: userData.username,
                        profilePicture: userData.profilePicture,
                    };
                }
                return null;
            })
        );

        // Filtrer les valeurs nulles (au cas où un utilisateur n'existe plus)
        setFollowersList(followers.filter(follower => follower !== null));
    };

    const fetchFollowingList = async (userId) => {
        const subscriptionsCollection = collection(db, "subscriptions");
        const q = query(subscriptionsCollection, where("subscriberId", "==", userId));
        const subscriptionSnapshot = await getDocs(q);

        // Récupérer les UIDs des abonnements
        const followingUids = subscriptionSnapshot.docs.map(doc => doc.data().subscribedToId);

        // Rechercher les données des abonnements par leurs UIDs
        const following = await Promise.all(
            followingUids.map(async (uid) => {
                const userDocRef = doc(db, "users", uid);
                const userDoc = await getDoc(userDocRef);
                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    return {
                        uid,
                        username: userData.username,
                        profilePicture: userData.profilePicture,
                    };
                }
                return null;
            })
        );

        // Filtrer les valeurs nulles (au cas où un utilisateur n'existe plus)
        setFollowingList(following.filter(followingUser => followingUser !== null));
    };

    return (
        <div className="flex mt-2 gap-2 cursor-pointer">
            <div className="flex-col gap-2 text-center" onClick={handleFollowersClick}>
                <p className="font-bold">{followersCount}</p>
                <p className="text-default-500">{t('followers')}</p>
            </div>

            <div className="flex-col gap-2 text-center cursor-pointer" onClick={handleFollowingClick}>
                <p className="font-bold">{followingCount}</p>
                <p className="text-default-500">{t('following')}</p>
            </div>

            <Modal scrollBehavior='inside' className='dark' isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <ModalContent>
                    <ModalHeader>{modalTitle}</ModalHeader>
                    <ModalBody>
                        <div className='flex flex-col  items-start gap-4' >
                            {(modalTitle === t('followers') ? followersList : followingList).map((user, index) => (
                                <Link href={`/profile/${user.username || "Unknown"}`} className="text-sm cursor-pointer hover:underline text-default-700 font-medium">

                                    <User
                                        key={index}
                                        avatarProps={{
                                            src: user.profilePicture // Utilisez simplement `user.profilePicture` sans accolades supplémentaires
                                        }}
                                        name={user.username}
                                    />
                                </Link>
                            ))}
                        </div>

                    </ModalBody>
                    <ModalFooter>

                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    );
};

export default UserFollowStats;
