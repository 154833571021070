import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../components/Firebase/firebaseConfig";
import { Divider } from "@nextui-org/react";

const SimilarImages = ({ objectName, currentImageId }) => {
    const [similarImages, setSimilarImages] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchSimilarImages = async () => {
            try {
                const imagesRef = collection(db, "images");
                const q = query(imagesRef, where("objects", "array-contains", objectName));
                const querySnapshot = await getDocs(q);

                const imagesData = querySnapshot.docs
                    .map((doc) => ({
                        id: doc.id,
                        ...doc.data()
                    }))
                    // Filtrer pour garder les images avec state et publish à true et exclure l'image actuelle
                    .filter((image) => image.id !== currentImageId && image.state === true && image.publish === true);

                setSimilarImages(imagesData);
            } catch (error) {
                console.error("Error fetching similar images:", error);
            } finally {
                setIsLoading(false); // Désactiver le chargement une fois les données récupérées
            }
        };

        if (objectName) {
            fetchSimilarImages();
        }
    }, [objectName, currentImageId]);

    if (isLoading || similarImages.length === 0) {
        return null; // Ne rien afficher si aucune image n'est trouvée ou si en chargement
    }

    return (
        <div>
            <Divider className="my mb-4" />
            <h2 className="mb-2 uppercase text-sm text-default-500 font-medium">Similar images</h2>

            <div className="columns-2   gap-2">
                {similarImages.map((image) => (
                    <Link to={`/image/${image.id}`} key={image.id}>
                        <div className="mb-2 break-inside-avoid hover:scale-105 ease-in-out duration-300 overflow-hidden rounded-md border border-stone-900">
                            <img
                                src={image.imageUrl}
                                alt={image.name}
                                className="w-full h-auto object-cover"
                            />
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default SimilarImages;
