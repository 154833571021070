import { db } from "./firebaseConfig";
import { collection, addDoc } from "firebase/firestore";

export const addLikeNotification = async (likedBy, likedById, imageId, recipientId, isComment = false, isReply = false) => {
    try {
        const notificationsCollection = collection(db, "notifications");

        let message;
        if (isReply) {
            message = `${likedBy} liked your reply.`;
        } else if (isComment) {
            message = `${likedBy} liked your comment.`;
        } else {
            message = `${likedBy} liked your image.`;
        }

        await addDoc(notificationsCollection, {
            type: "like",
            message: message,
            timestamp: new Date().toISOString(),
            read: false,
            imageId,
            recipientId,  // Destinataire de la notification
            likedById,    // Identifiant de l'utilisateur qui a aimé
            isComment,    // Indique s'il s'agit d'un commentaire
            isReply       // Indique s'il s'agit d'une réponse
        });
    } catch (error) {
        console.error("Error adding like notification: ", error);
    }
};



export const addCommentNotification = async (commentedBy, commentedById, imageId, recipientId, commentText) => {
    try {
        const notificationsCollection = collection(db, "notifications");
        await addDoc(notificationsCollection, {
            type: "comment",
            message: `${commentedBy} commented on your image.`,
            timestamp: new Date().toISOString(),
            read: false,
            imageId,
            commentText: commentText, // Ajouter le texte du commentaire
            recipientId,
            commentedById
        });
    } catch (error) {
        console.error("Error adding comment notification: ", error);
    }
};

export const addReplyNotification = async (repliedBy, repliedById, imageId, commentId, recipientId, commentText) => {
    try {
        const notificationsCollection = collection(db, "notifications");
        await addDoc(notificationsCollection, {
            type: "reply",
            message: `${repliedBy} replied to your comment.`,
            timestamp: new Date().toISOString(),
            read: false,
            imageId,
            commentText: commentText, // Ajouter le texte du commentaire
            commentId,
            recipientId,
            repliedById
        });
    } catch (error) {
        console.error("Error adding reply notification: ", error);
    }
};
