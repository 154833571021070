import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../components/Firebase/firebaseConfig";
import { Divider, Tooltip } from "@nextui-org/react";


const UserImages = ({ userId, currentImageId }) => {
    const [userImages, setUserImages] = useState([]);

    useEffect(() => {
        const fetchUserImages = async () => {
            try {
                const imagesRef = collection(db, "images");
                const q = query(imagesRef, where("author", "==", userId));
                const querySnapshot = await getDocs(q);

                const imagesData = querySnapshot.docs
                    .map((doc) => ({
                        id: doc.id,
                        ...doc.data()
                    }))
                    .filter((image) => image.id !== currentImageId && image.state === true && image.publish === true);


                setUserImages(imagesData);
            } catch (error) {
                console.error("Error fetching user images:", error);
            }
        };

        if (userId) {
            fetchUserImages();
        }
    }, [userId, currentImageId]);

    if (!userImages.length) {
        return <p>No images found for this user.</p>;
    }

    return (
        <div>
            <Divider className="my mb-4" />
            <h2 className="mb-2 uppercase text-sm text-default-500 font-medium">{userImages[0].username}'s images</h2>
            {/* Masonry layout */}
            <div className="columns-2  gap-2">
                {userImages.map((image) => (

                    <Link to={`/image/${image.id}`} key={image.id}>
                        <div className="mb-2 break-inside-avoid hover:scale-105 ease-in-out duration-300 overflow-hidden rounded-md border border-stone-900">
                            <img
                                src={image.imageUrl}
                                alt={image.name}
                                className="w-full h-auto object-cover"
                            />
                        </div>
                    </Link>

                ))}
            </div>
        </div>
    );
};

export default UserImages;
