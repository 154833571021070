import React, { useRef, useEffect } from 'react';

const SkyMap = () => {
  const canvasRef = useRef(null);

  // Exemple de données d'étoiles
  const starsData = [
    { ra: 2.097, dec: 29.090, magnitude: 1.5 },
    { ra: 5.242, dec: -8.201, magnitude: 2.1 },
    { ra: 6.756, dec: -16.716, magnitude: 2.4 },
    { ra: 7.755, dec: 28.026, magnitude: 2.0 },
    { ra: 8.139, dec: -47.336, magnitude: 1.8 },
    // Ajoutez plus d'étoiles ici
  ];

  const drawStars = (ctx, width, height) => {
    // Convertir les coordonnées célestes (RA/DEC) en coordonnées du canvas
    starsData.forEach((star) => {
      // Transformation basique des coordonnées (à améliorer selon la projection souhaitée)
      const x = (star.ra / 24) * width; // RA est de 0 à 24 heures
      const y = height / 2 - (star.dec / 90) * (height / 2); // DEC est de -90 à +90 degrés

      // Dessiner l'étoile
      const radius = Math.max(0.5, 3 - star.magnitude); // Plus l'étoile est brillante, plus le rayon est grand
      ctx.beginPath();
      ctx.arc(x, y, radius, 0, 2 * Math.PI);
      ctx.fillStyle = 'white';
      ctx.fill();
    });
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    // Définir les dimensions du canvas
    const width = canvas.width;
    const height = canvas.height;

    // Fond noir pour le ciel
    ctx.fillStyle = 'black';
    ctx.fillRect(0, 0, width, height);

    // Dessiner les étoiles
    drawStars(ctx, width, height);
  }, []);

  return <canvas ref={canvasRef} width={800} height={600} />;
};

export default SkyMap;
