import React, { createContext, useState, useEffect, useMemo, useCallback } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, onSnapshot } from 'firebase/firestore';
import { auth, db } from './Firebase/firebaseConfig';

const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(() => {
    const savedUser = localStorage.getItem('currentUser');
    return savedUser ? JSON.parse(savedUser) : null;
  });
  const [loading, setLoading] = useState(true);

  const fetchUserData = useCallback(async (user) => {
    if (user) {
      try {
        const userDocRef = doc(db, 'users', user.uid);
        const unsubscribeSnapshot = onSnapshot(
          userDocRef,
          (docSnapshot) => {
            if (docSnapshot.exists()) {
              const userData = docSnapshot.data();
              const userToStore = {
                uid: user.uid,
                email: user.email,
                username: userData.username,
                isAdmin: userData.isAdmin,
                profilePicture: userData.profilePicture,
                receiveNewsletter: userData.receiveNewsletter,
                isPro: userData.isPro,
              };
              setCurrentUser(userToStore);
              localStorage.setItem('currentUser', JSON.stringify(userToStore));
            }
            setLoading(false); // Arrêter le chargement après la mise à jour des données
          },
          (error) => {
            console.error('Error fetching user data:', error);
            setCurrentUser(null);
            localStorage.removeItem('currentUser');
            setLoading(false); // Arrêter le chargement en cas d'erreur
          }
        );

        return () => unsubscribeSnapshot();
      } catch (error) {
        console.error('Error fetching user data:', error);
        setCurrentUser(null);
        localStorage.removeItem('currentUser');
        setLoading(false);
      }
    } else {
      setCurrentUser(null);
      localStorage.removeItem('currentUser');
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      setLoading(true);
      fetchUserData(user);
    });

    return () => unsubscribeAuth();
  }, [fetchUserData]);

  const contextValue = useMemo(() => ({ currentUser, loading }), [currentUser, loading]);

  useEffect(() => {
    console.log('Utilisateur actuel :', currentUser);
    console.log('Chargement en cours :', loading);
  }, [currentUser, loading]);

  return (
    <UserContext.Provider value={contextValue}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
