import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import SearchContext from './SearchContext';
import { SearchNormal1, Add } from "iconsax-react";

const SearchBar = () => {
    const { allData, setResults, selectedTags, setSelectedTags, searchQuery, setSearchQuery } = useContext(SearchContext);
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const suggestionsRef = useRef(null);
    const inputRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/search' && inputRef.current) {
            inputRef.current.focus(); // Mettre le focus sur l'input
        }
    }, [location]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                suggestionsRef.current &&
                !suggestionsRef.current.contains(event.target) &&
                inputRef.current &&
                !inputRef.current.contains(event.target)
            ) {
                setFilteredOptions([]);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [suggestionsRef, inputRef]);

    const filterOptions = (inputValue) => {
        if (!inputValue) {
            setFilteredOptions([]);
            return;
        }

        const allOptions = new Map(); // Utilisez un Map pour assurer l'unicité

        allData.forEach((data) => {
            // Vérifier les métadonnées des images et objets liés
            const { metadata, objects, username } = data;
            if (metadata || objects) {
                const { Accessory, Camera, Filter, Mount, Software, Telescope, Object } = metadata || {};
                const categories = [
                    { group: 'Accessory', items: Accessory },
                    { group: 'Camera', items: Camera },
                    { group: 'Filter', items: Filter },
                    { group: 'Mount', items: Mount },
                    { group: 'Software', items: Software },
                    { group: 'Telescope', items: Telescope },
                    { group: 'Object', items: Object },
                    { group: 'Objects', items: objects },
                ];

                categories.forEach(({ group, items }) => {
                    items?.forEach(item => {
                        const formattedItem = item.toLowerCase();
                        if (formattedItem.includes(inputValue.toLowerCase())) {
                            const uniqueKey = `${group}-${formattedItem}`;
                            if (!allOptions.has(uniqueKey)) {
                                allOptions.set(uniqueKey, { group, value: formattedItem });
                            }
                        }
                    });
                });
            }

            // Ajouter la recherche des noms d'utilisateurs
            if (username && username.toLowerCase().includes(inputValue.toLowerCase())) {
                const uniqueKey = `User-${username.toLowerCase()}`;
                if (!allOptions.has(uniqueKey)) {
                    allOptions.set(uniqueKey, { group: 'User', value: username, userId: data.id });
                }
            }
        });

        setFilteredOptions(Array.from(allOptions.values()));
    };


    const debounce = (func, delay) => {
        let timeoutId;
        return (...args) => {
            if (timeoutId) clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };

    const debouncedFilterOptions = debounce(filterOptions, 200);

    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        setSearchQuery(inputValue);

        // N'effectuer le filtrage que si l'utilisateur a tapé au moins 2 caractères
        if (inputValue.length >= 3) {
            debouncedFilterOptions(inputValue);
        } else {
            setFilteredOptions([]); // Effacer les suggestions si moins de 2 caractères
        }

        if (!location.pathname.startsWith('/search')) {
            navigate(`/search?q=${inputValue}`);
        }
    };

    const handleTagSelect = (tag) => {
        if (tag.group === 'User') {
            // Rediriger vers le profil utilisateur en utilisant le username
            navigate(`/profile/${tag.value}`);
        } else {
            // Ajouter le tag sélectionné aux tags actifs
            if (!selectedTags.some(t => t.value === tag.value && t.group === tag.group)) {
                setSelectedTags(prevTags => [...prevTags, tag]);
            }
        }
        setSearchQuery('');
        setFilteredOptions([]);
    };


    const handleTagRemove = (tag) => {
        setSelectedTags(prevTags => prevTags.filter(t => t !== tag));
    };

    return (
        <div className="relative flex flex-col w-full z-50">
            <div className='flex items-center'>
                <SearchNormal1 size={18} className="absolute left-3 text-gray-400" />

                <input
                    ref={inputRef}
                    type="text"
                    value={searchQuery}
                    onChange={handleInputChange}
                    placeholder="Search..."
                    className="focus:outline-none focus:ring-2 focus:ring-opacity-50 focus:ring-primary-300 p-2 pl-10 bg-default-100 rounded-lg w-full text-md md:text-sm md:w-1/3"
                />

                {searchQuery && (
                    <button
                        className="relative right-7"
                        onClick={() => {
                            setSearchQuery('');
                            setFilteredOptions([]);
                        }}
                    >
                        <Add size={24} variant="Linear" className='rotate-45' />
                    </button>
                )}
            </div>

            {filteredOptions.length > 0 && (
                <ul ref={suggestionsRef} className="py-2 absolute bg-default-100 shadow-md rounded-lg max-h-60 overflow-auto z-10 mt-11 w-full text-md md:text-sm md:w-1/3">
                    {Object.entries(
                        filteredOptions.reduce((acc, option) => {
                            if (!acc[option.group]) {
                                acc[option.group] = [];
                            }
                            acc[option.group].push(option);
                            return acc;
                        }, {})
                    ).map(([group, options]) => (
                        <li key={group}>
                            <div className="pb-px text-xs text-default-500 p-4 py-2 uppercase font-medium">{group}</div>
                            <ul>
                                {options.slice(0, 5).map((option, index) => (
                                    <li
                                        key={index}
                                        className="p-4 py-2 cursor-pointer hover:bg-default-200 text-md md:text-sm capitalize"
                                        onClick={() => handleTagSelect(option)}
                                    >
                                        {option.value}
                                    </li>
                                ))}
                            </ul>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default SearchBar;
