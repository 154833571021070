import React, { useState, useEffect } from 'react';
import { Button, Input, Listbox, ListboxItem, Divider } from '@nextui-org/react';
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';
import { auth } from "./Firebase/firebaseConfig";
import CreatableAsyncSelect from 'react-select/async-creatable';
import { Add } from "iconsax-react";
import { useAptabase } from '@aptabase/react';
import ModaleCollaborative from './ModaleCollaborative'
import { useTranslation } from 'react-i18next';  // Importation du hook i18next


const customStyles = {
    control: (provided, state) => ({
        ...provided,
        backgroundColor: 'hsl(var(--nextui-default-100))',
        '&:hover': {
            borderColor: 'transparent',
        },
        borderColor: state.isFocused ? 'transparent' : 'transparent',
        borderRadius: '12px',
        height: 'fit-content',
        minHeight: '44px',
        boxShadow: state.isFocused ? 'transparent' : 'transparent',
        cursor: state.isFocused ? 'p' : 'pointer',
        display: 'flex',
        alignItems: 'center',
    }),
    menu: (provided) => ({
        ...provided,
        backgroundColor: 'hsl(var(--nextui-default-100))',
        color: 'hsl(var(--nextui-default-900))',
        borderRadius: '8px',
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused
            ? 'hsl(var(--nextui-default-200))'
            : 'hsl(var(--nextui-default-100))',
        color: 'hsl(var(--nextui-default-900))',
        fontSize: '0.875rem',
        cursor: 'pointer',
    }),
    multiValue: (provided) => ({
        ...provided,
        backgroundColor: 'hsl(var(--nextui-default-300))',
        borderRadius: '24px',
        paddingInlineStart: '8px',
        paddingBlock: '2px',
        color: 'hsl(var(--nextui-default-900))',
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        color: 'hsl(var(--nextui-default-900))',
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        color: 'hsl(var(--nextui-default-900))',
        paddingInline: '6px',
        marginInlineStart: '8px',
        ':hover': {
            backgroundColor: 'hsl(var(--nextui-default-900))',
            color: 'hsl(var(--nextui-default-200))',
        },
        borderRadius: '24px',
    }),
    input: (provided) => ({
        ...provided,
        color: 'hsl(var(--nextui-default-900))',
        fontSize: '0.875rem',
    }),
    placeholder: (provided) => ({
        ...provided,
        fontSize: '0.875rem',
        color: 'hsl(var(--nextui-default-500))',
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: "none",
    }),
};

const SetupManager = () => {
    const [setupData, setSetupData] = useState({
        telescope: [],
        camera: [],
        filter: [],
        accessory: [],
        software: [],
        mount: [],
    });
    const [newSetupName, setNewSetupName] = useState('');
    const [setups, setSetups] = useState([]);
    const [selectedSetup, setSelectedSetup] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const db = getFirestore();
    const { t } = useTranslation();


    const fetchSetups = async () => {
        try {
            const user = auth.currentUser;
            if (user) {
                const setupsDocRef = doc(db, 'users', user.uid, 'cosmio', 'setups');
                const setupsDoc = await getDoc(setupsDocRef);
                if (setupsDoc.exists()) {
                    const setupsData = setupsDoc.data();
                    setSetups(Object.entries(setupsData));
                }
            }
        } catch (error) {
            console.error("Error fetching setups:", error);
        }
    };

    const fetchCollectionOptions = async (collectionName, inputValue) => {
        const docRef = doc(db, 'system', collectionName);
        const docSnapshot = await getDoc(docRef);
        if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            if (collectionName in data) {
                return data[collectionName]
                    .filter(item => item.toLowerCase().includes(inputValue.toLowerCase()))
                    .map(item => ({
                        value: item,
                        label: item
                    }))
                    .slice(0, 10);
            } else {
                console.log(`No such field ${collectionName} in document ${collectionName}!`);
                return [];
            }
        } else {
            console.log(`No such document for ${collectionName}!`);
            return [];
        }
    };

    const loadOptions = async (collectionName, inputValue, callback) => {
        const options = await fetchCollectionOptions(collectionName, inputValue);
        callback(options);
    };

    useEffect(() => {
        fetchSetups();
    }, []);

    const handleChange = (key, value) => {
        setSetupData({
            ...setupData,
            [key]: value
        });
    };

    const handleSaveSetup = async () => {
        setIsLoading(true);
        try {
            const user = auth.currentUser;
            if (user) {
                const setupsDocRef = doc(db, 'users', user.uid, 'cosmio', 'setups');
                const setupsDoc = await getDoc(setupsDocRef);
                const setups = setupsDoc.exists() ? setupsDoc.data() : {};
                const newSetupId = selectedSetup || newSetupName;

                const updatedSetups = {
                    ...setups,
                    [newSetupId]: setupData,
                };
                await setDoc(setupsDocRef, updatedSetups);
                setSetupData({
                    telescope: [],
                    camera: [],
                    filter: [],
                    accessory: [],
                    software: [],
                    mount: [],
                });
         
                setSelectedSetup('');
                setNewSetupName('');
                fetchSetups();
            }
        } catch (error) {
            console.error("Error saving setup:", error);
        } finally {
            setIsLoading(false);
        }
    };


    const handleDeleteSetup = async () => {
        if (!selectedSetup) return;
        setIsLoading(true);
        try {
            const user = auth.currentUser;
            if (user) {
                const setupsDocRef = doc(db, 'users', user.uid, 'cosmio', 'setups');
                const setupsDoc = await getDoc(setupsDocRef);
                if (setupsDoc.exists()) {
                    const setups = setupsDoc.data();
                    delete setups[selectedSetup];
                    await setDoc(setupsDocRef, setups);
                    setSetupData({
                        telescope: [],
                        camera: [],
                        filter: [],
                        accessory: [],
                        software: [],
                        mount: [],
                    });
                    setSelectedSetup('');
                    fetchSetups();
                }
            }
        } catch (error) {
            console.error("Error deleting setup:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSelectSetup = (key) => {
        if (key === 'new') {
            setSelectedSetup('');
            setNewSetupName('');
            setSetupData({
                telescope: [],
                camera: [],
                filter: [],
                accessory: [],
                software: [],
                mount: [],
            });
        } else {
            setSelectedSetup(key);
            const setup = setups.find(([id]) => id === key);
            if (setup) {
                setSetupData(setup[1]);
            }
            setNewSetupName('');
        }
    };


    return (
        <div className='flex flex-row h-full gap-6 w-full'>

            <div>

                <Listbox selectionMode="single" aria-label={t('setupManager.setups')} onAction={handleSelectSetup}>

                    <ListboxItem
                        className=" font-bold  rounded-lg gap-1"
                        key="new"
                        startContent={<Add size={18} variant="linear" />}
                    >
                        {t('setupManager.createSetup')}
                    </ListboxItem>

                    {setups.map(([id]) => (
                        <ListboxItem key={id} value={id}>{id}</ListboxItem>
                    ))}
                </Listbox>
            </div>
            <Divider orientation="vertical" />


            <div className="flex flex-col gap-4 w-full">

                <p className='text-xl font-medium' >{selectedSetup || t('setupManager.createSetup')}</p>
                <ModaleCollaborative />
                <div  >
                    <label>{t('setupManager.name')}</label>
                    <Input
                        className='dark'
                        type="text"
                        placeholder={t('setupManager.namePlaceholder')}
                        value={newSetupName}
                        onChange={(e) => setNewSetupName(e.target.value)}
                    />
                </div>
                <div className='grid sm:grid-cols-1 md:grid-cols-2 gap-4'>
                    <div  >
                        <label>{t('setupManager.telescope')}</label>
                        <CreatableAsyncSelect
                            isMulti
                            cacheOptions
                            loadOptions={(inputValue, callback) => loadOptions('telescope', inputValue, callback)}
                            defaultOptions={[]}
                            value={setupData.telescope.map(value => ({ label: value, value }))}
                            onChange={(selectedOptions) => handleChange('telescope', selectedOptions ? selectedOptions.map(option => option.value) : [])}
                            placeholder={t('setupManager.enterTelescope')}
                            styles={customStyles}
                        />
                    </div>

                    <div>
                        <label>{t('setupManager.camera')}</label>
                        <CreatableAsyncSelect
                            isMulti
                            cacheOptions
                            loadOptions={(inputValue, callback) => loadOptions('camera', inputValue, callback)}
                            defaultOptions={[]}
                            value={setupData.camera.map(value => ({ label: value, value }))}
                            onChange={(selectedOptions) => handleChange('camera', selectedOptions ? selectedOptions.map(option => option.value) : [])}
                            placeholder={t('setupManager.enterCamera')}
                            styles={customStyles}
                        />
                    </div>
                    <div>
                        <label>{t('setupManager.filter')}</label>
                        <CreatableAsyncSelect
                            isMulti
                            cacheOptions
                            loadOptions={(inputValue, callback) => loadOptions('filter', inputValue, callback)}
                            defaultOptions={[]}
                            value={setupData.filter.map(value => ({ label: value, value }))}
                            onChange={(selectedOptions) => handleChange('filter', selectedOptions ? selectedOptions.map(option => option.value) : [])}
                            placeholder={t('setupManager.enterFilter')}
                            styles={customStyles}
                        />
                    </div>

                    <div>
                        <label>{t('setupManager.accessory')}</label>
                        <CreatableAsyncSelect
                            isMulti
                            cacheOptions
                            loadOptions={(inputValue, callback) => loadOptions('accessory', inputValue, callback)}
                            defaultOptions={[]}
                            value={setupData.accessory.map(value => ({ label: value, value }))}
                            onChange={(selectedOptions) => handleChange('accessory', selectedOptions ? selectedOptions.map(option => option.value) : [])}
                            placeholder={t('setupManager.enterAccessory')}
                            styles={customStyles}
                        />
                    </div>

                    <div>
                        <label>{t('setupManager.software')}</label>
                        <CreatableAsyncSelect
                            isMulti
                            cacheOptions
                            loadOptions={(inputValue, callback) => loadOptions('software', inputValue, callback)}
                            defaultOptions={[]}
                            value={setupData.software.map(value => ({ label: value, value }))}
                            onChange={(selectedOptions) => handleChange('software', selectedOptions ? selectedOptions.map(option => option.value) : [])}
                            placeholder={t('setupManager.enterSoftware')}
                            styles={customStyles}
                        />
                    </div>

                    <div>
                        <label>{t('setupManager.mount')}</label>
                        <CreatableAsyncSelect
                            isMulti
                            cacheOptions
                            loadOptions={(inputValue, callback) => loadOptions('mount', inputValue, callback)}
                            defaultOptions={[]}
                            value={setupData.mount.map(value => ({ label: value, value }))}
                            onChange={(selectedOptions) => handleChange('mount', selectedOptions ? selectedOptions.map(option => option.value) : [])}
                            placeholder={t('setupManager.enterMount')}
                            styles={customStyles}
                        />
                    </div>
                </div>
                <div className='flex flex-row gap-2 w-full'>
                    {selectedSetup && (
                        <Button className='w-full' onPress={handleDeleteSetup} color="default" isLoading={isLoading} disabled={isLoading}>
                            {t('setupManager.delete')}
                        </Button>
                    )}
                    <Button color="primary" className='w-full' onPress={handleSaveSetup} isLoading={isLoading} disabled={isLoading}>
                        {selectedSetup ? t('setupManager.save') : t('setupManager.create')}
                    </Button>

                </div>

            </div>


        </div>
    );
};

export default SetupManager;
