import React, { useState, useEffect, useContext } from "react";
import { collection, getDocs } from "firebase/firestore";
import { useLocation, useNavigate } from "react-router-dom";
import { db } from "./Firebase/firebaseConfig";
import ImageCard from "./ImageCard";
import { Pagination, DateRangePicker, Divider, Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Button } from "@nextui-org/react";
import LoginModal from "./LoginModal";
import SearchContext from "./SearchContext";
import { parseISO, isWithinInterval } from 'date-fns';
import { CloseCircle, ArrowDown2 } from "iconsax-react";

const ITEMS_PER_PAGE = 20;

function Search() {
    const { allData, setAllData, setAllUsers, selectedTags, setSelectedTags, searchQuery, setSearchQuery } = useContext(SearchContext);
    const [loading, setLoading] = useState(true);
    const [isSearch, setIsSearch] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [filteredResults, setFilteredResults] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedMetadata, setSelectedMetadata] = useState(null);
    const [dateRange, setDateRange] = useState({
        start: null,
        end: null,
    });
    const [sortOrder, setSortOrder] = useState('mostRecent');

    const location = useLocation();
    const navigate = useNavigate();

    // Lire la valeur de `q` depuis l'URL et mettre à jour `searchQuery`
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const queryFromUrl = queryParams.get('q') || '';

        if (queryFromUrl) {
            setSearchQuery(queryFromUrl);
        }
    }, [location.search, setSearchQuery]);

    // Mettre à jour l'URL chaque fois que `searchQuery` change
    useEffect(() => {
        if (searchQuery) {
            const newSearchParams = new URLSearchParams(location.search);
            newSearchParams.set('q', searchQuery);
            navigate(`?${newSearchParams.toString()}`, { replace: true });
        }
    }, [searchQuery, navigate, location.search]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const imagesCollection = collection(db, "images");
                const usersCollection = collection(db, "users");

                const [imagesSnapshot, usersSnapshot] = await Promise.all([
                    getDocs(imagesCollection),
                    getDocs(usersCollection),
                ]);

                const imageData = imagesSnapshot.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }))
                    .filter(image => image.state === true && image.publish === true);

                // Trier les images selon la valeur actuelle de `sortOrder`
                const sortedImages = sortOrder === 'mostRecent'
                    ? imageData.sort((a, b) => b.timestamp.seconds - a.timestamp.seconds)
                    : imageData.sort((a, b) => a.timestamp.seconds - b.timestamp.seconds);

                const userData = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

                setAllData(sortedImages);
                setAllUsers(userData);
                setFilteredResults(sortedImages);
            } catch (error) {
                console.error("Error fetching data: ", error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [setAllData, setAllUsers, sortOrder]);

    // Utiliser useEffect pour filtrer les résultats de la recherche et la plage de dates
    useEffect(() => {
        if (searchQuery || selectedTags.length > 0 || dateRange.start || dateRange.end) {
            setIsSearch(true);
            const searchTerms = searchQuery.toLowerCase().split(' ').filter(Boolean);

            const results = (allData || []).filter(data => {
                if (!data.metadata) return false;

                const { Description, Accessory, Camera, Filter, LocationName, Mount, Software, Telescope, Object } = data.metadata;
                const objects = data.objects || [];

                const searchableFields = [
                    Description || "",
                    ...(Accessory || []),
                    ...(Camera || []),
                    ...(Filter || []),
                    LocationName || "",
                    ...(Mount || []),
                    ...(Software || []),
                    ...(Telescope || []),
                    ...(Object || []),
                    ...objects
                ];

                const searchableString = searchableFields
                    .filter(Boolean)
                    .map(item => item.toLowerCase())
                    .join(" ");

                const matchesQuery = searchTerms.every(term => searchableString.includes(term));

                // Vérifier si les tags sont présents dans les métadonnées
                const matchesTags = selectedTags.every(tag => searchableString.includes(tag.value));

                const matchesDateRange = dateRange.start && dateRange.end && data.timestamp
                    ? isWithinInterval(parseISO(data.timestamp.toDate().toISOString()), {
                        start: dateRange.start.toDate(),
                        end: dateRange.end.toDate(),
                    })
                    : true;

                return matchesQuery && matchesTags && matchesDateRange;
            });

            setFilteredResults(results);
        } else {
            setFilteredResults(allData || []);
            setIsSearch(false);
        }
    }, [searchQuery, selectedTags, dateRange, allData]);

    const handleOpenModal = (metadata) => {
        setSelectedMetadata(metadata);
        setIsModalVisible(true);
    };

    const handleTagRemove = (tag) => {
        setSelectedTags(prevTags => prevTags.filter(t => t.value !== tag.value));
    };

    const handleSortChange = (key) => {
        setSortOrder(key);
    };

    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const paginatedResults = filteredResults.slice(startIndex, startIndex + ITEMS_PER_PAGE);

    return (
        <div className="w-full min-h-screen">
            {loading ? (
                <div>Loading...</div>
            ) : (
                filteredResults && filteredResults.length === 0 ? (
                    <div className="text-center">No results found</div>
                ) : (
                    <>
                        {isSearch && (
                            <>
                                <Divider />
                                <div className="flex flex-col md:flex-row justify-between gap-4 py-3">
                                    {selectedTags.length > 0 && (
                                        <div className="flex flex-row gap-2 items-center ">
                                            <p>Active tags :</p>
                                            <div className='flex flex-wrap gap-1'>
                                                {selectedTags.map((tag, index) => (
                                                    <div key={index} className="flex pl-3 py-1 px-1 w-fit bg-primary-500 border-1 border-primary-400 rounded-full text-md md:text-sm items-center font-medium capitalize text-nowrap overflow-auto">
                                                        {tag.value}
                                                        <button className="ml-2" onClick={() => handleTagRemove(tag)}>
                                                            <CloseCircle size={20} variant="Bold" />
                                                        </button>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}

                                    <div className="flex md:items-center flex-col md:flex-row gap-4 ">
                                        <DateRangePicker
                                            label="Date range"
                                            value={dateRange}
                                            onChange={setDateRange}
                                            placeholder="Select a date range"
                                            className="w-fit"
                                          
                                        />
                                    </div>
                                </div>
                                <Divider />

                                <div className="flex items-center justify-between" >
                                    <p className="font-medium text-xl mb-2 mt-4">{filteredResults.length} images found</p>

                                    <div>
                                        <Dropdown className="dark">
                                            <DropdownTrigger>
                                                <Button variant="light">
                                                    {sortOrder === 'mostRecent' ? 'Most Recent' : 'Oldest'}
                                                    <ArrowDown2 size='16' />
                                                </Button>
                                            </DropdownTrigger>
                                            <DropdownMenu aria-label="Sort Options" onAction={handleSortChange}>
                                                <DropdownItem key="mostRecent">Most Recent</DropdownItem>
                                                <DropdownItem key="oldest">Oldest</DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                </div>

                            </>
                        )}

                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                            {paginatedResults.map((result) => (
                                <ImageCard key={result.id} image={result} onClick={() => handleOpenModal(result.metadata)} />
                            ))}
                        </div>


                        <div className="flex justify-center mt-4">
                            <Pagination
                                variant="light"
                                showControls
                                total={Math.ceil(filteredResults.length / ITEMS_PER_PAGE)}
                                initialPage={currentPage}
                                onChange={(page) => setCurrentPage(page)}
                            />
                        </div>
                    </>
                )
            )}
            <LoginModal isOpen={isModalVisible} onClose={() => setIsModalVisible(false)} />
        </div>
    );
}

export default Search;
