import React, { useEffect, useState, useRef, useContext } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Share } from '@capacitor/share';
import { Helmet } from 'react-helmet';
import SimilarImages from "../components/SimilarImages";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { doc, getDoc, deleteDoc } from "firebase/firestore";
import { getStorage, ref, deleteObject } from "firebase/storage";
import { db } from "../components/Firebase/firebaseConfig";
import { Button, Chip, Switch, Tooltip, User, Divider, Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, ScrollShadow, Spinner, Slider, Avatar } from "@nextui-org/react";
import CommentSection from "./CommentSection";
import LikeButton from "./LikeButton";
import UserImages from "./UserImages";
import CustomChip from '../components/CustomChip';
import { Maximize3, Image, Scanning, Edit2, ImportCurve, More, Trash, Add, ExportCurve, ExportSquare, Setting4, Back, ArrowLeft2, ArrowRight2 } from "iconsax-react";
import UserContext from "../components/UserContext";
import { formatDistanceToNow } from "date-fns";
import AstronomicalEvent from "./CompleteNameObject";
import Tutorial from "./Tutorial";
import { useTranslation } from 'react-i18next';  // Importation du hook i18next
import ImageEditor from "./ImageEditor"; // Assure-toi de l'importer correctement
import ImageNavigator from "../components/ImageNavigator"; // Import du nouveau composant


const ImageDetails = () => {
    const navigate = useNavigate(); // Hook to navigate programmatically
    const location = useLocation(); // Hook to access current location
    const modalRef = useRef(null);

    const { imageId } = useParams(); // Utilisation de useParams pour récupérer l'ID d'image

    const [isMinimized, setIsMinimized] = useState(false);
    const [image, setImage] = useState(null); // State to hold the image data
    const [showAnnotated, setShowAnnotated] = useState(false); // State to toggle annotated image view
    const [annotatedAvailable, setAnnotatedAvailable] = useState(false); // State to check if annotated image is available
    const imageRef = useRef(null); // Ref to hold image element
    const commentInputRef = useRef(null); // Ref to hold comment input element

    console.log(image)
    const { currentUser } = useContext(UserContext); // Context to get the current user
    const storage = getStorage(); // Get Firebase storage instance
    const [profilePicture, setProfilePicture] = useState(''); // State to hold profile picture URL
    const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility
    const [showAlert, setShowAlert] = useState(false);
    const { t } = useTranslation();  // Utilisation du hook pour les traductions

    const handleReport = () => {
        alert("Moderators have been informed of the report and will act as soon as possible.")
    };

    const [isZoomed, setIsZoomed] = useState(false); // Track zoom status
    const [isMobile, setIsMobile] = useState(false); // Nouvel état pour mobile
    const [brightness, setBrightness] = useState(100);
    const [contrast, setContrast] = useState(100);
    const [saturation, setSaturation] = useState(100);
    const [showSettings, setShowSettings] = useState(false); // Nouvel état pour les réglages
    const [navigateToNextImage, setNavigateToNextImage] = useState(null);
    const [navigateToPreviousImage, setNavigateToPreviousImage] = useState(null);
    const [isInactive, setIsInactive] = useState(false);
    const imageContainerRef = useRef(null);




    // Fonction pour basculer la visibilité des réglages
    const toggleSettings = () => {
        setShowSettings(!showSettings);
    };


    // Style dynamique pour appliquer les filtres à l'image
    const imageStyle = {
        filter: `brightness(${brightness}%) contrast(${contrast}%) saturate(${saturation}%)`,
    };


    // Fonction pour partager l'image
    const handleShare = async () => {
        await Share.share({
            title: 'Check out this astronomy image!',
            text: 'Here is a cool astronomy image I found on Cosmio!',
            url: window.location.href,
            dialogTitle: 'Share this image',
        });
    };



    const handleCopyUrl = async () => {
        try {
            await navigator.clipboard.writeText(window.location.href);
            alert('URL copied to clipboard');
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };


    useEffect(() => {
        let timer;

        const handleMouseMove = () => {
            setIsInactive(false);
            clearTimeout(timer);

            // Masquer les boutons après 3 secondes d'inactivité
            timer = setTimeout(() => {
                setIsInactive(true);
            }, 1000); // Temps d'inactivité (en millisecondes)
        };

        // Écoute les mouvements de souris
        window.addEventListener('mousemove', handleMouseMove);

        // Nettoyer l'événement à la fin du cycle de vie du composant
        return () => {
            clearTimeout(timer);
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);


    // Effect to handle modal visibility based on URL parameters
    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const imageIdFromUrl = urlParams.get('image');
        if (imageIdFromUrl === imageId) {
            setIsModalOpen(true);
        } else {
            setIsModalOpen(false);
        }
    }, [location.search, imageId]);


    // Effect to fetch profile picture of the image author
    useEffect(() => {
        const fetchProfilePicture = async () => {
            if (image && image.author) {
                const picture = await getProfilePicture(image.author);
                setProfilePicture(picture || 'https://example.com/default-profile.jpg');
            }
        };
        fetchProfilePicture();
    }, [image]);


    useEffect(() => {
        // Fonction pour récupérer les détails de l'image
        const fetchImage = async () => {
            try {
                const imageRef = doc(db, "images", imageId);
                const imageDoc = await getDoc(imageRef);
                if (imageDoc.exists()) {
                    const imageData = { id: imageDoc.id, ...imageDoc.data() };
                    setImage(imageData);


                    // Vérifie si l'image annotée est disponible
                    if (imageData.annotatedImageUrl) {
                        setAnnotatedAvailable(true); // Activer la possibilité de basculer entre les images
                    } else {
                        setAnnotatedAvailable(false); // Désactiver si aucune image annotée
                    }
                } else {
                    console.error("Image not found");
                }
            } catch (error) {
                console.error("Error fetching the image:", error);
            }
        };

        if (imageId) {
            fetchImage();
        }
    }, [imageId]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        setIsModalOpen(searchParams.has("modal"));
    }, [location.search]);


    useEffect(() => {
        if (modalRef.current) {
            modalRef.current.focus(); // Mettre le focus sur la div lorsque `location` change
        }
    }, [location]); // Ajoute `location` comme dépendance

    // Détecter si l'utilisateur est sur un appareil mobile
    useEffect(() => {
        const checkIfMobile = () => {
            const isMobileDevice = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
            setIsMobile(isMobileDevice);
        };
        checkIfMobile();
    }, []);

    // Effect to handle comment input focus event for smooth scrolling
    useEffect(() => {
        const handleFocus = (e) => {
            setTimeout(() => {
                e.target.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }, 300);
        };

        if (commentInputRef.current) {
            const inputElement = commentInputRef.current;
            inputElement.addEventListener('focus', handleFocus);

            return () => {
                inputElement.removeEventListener('focus', handleFocus);
            };
        }
    }, [commentInputRef.current]);

    if (!image) {
        return (
            <div className="fixed inset-0 flex justify-center items-center bg-black z-50">
                <Spinner size="lg" />
            </div>
        );
    }

    // Function to toggle annotated image view
    const toggleAnnotated = () => {
        setShowAnnotated(!showAnnotated);
    };

    const imageUrl = showAnnotated
        ? image.annotatedImageUrl
        : image.imageUrl;

    // Function to handle fullscreen image display
    const handleFullscreen = () => {
        if (imageRef.current) {
            if (imageRef.current.requestFullscreen) {
                imageRef.current.requestFullscreen();
            } else if (imageRef.current.mozRequestFullScreen) {
                imageRef.current.mozRequestFullScreen();
            } else if (imageRef.current.webkitRequestFullscreen) {
                imageRef.current.webkitRequestFullscreen();
            } else if (imageRef.current.msRequestFullscreen) {
                imageRef.current.msRequestFullscreen();
            }
        }
    };

    // Function to handle image download
    const handleDownload = (url) => {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'image.jpg');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const timeSincePosted = formatDistanceToNow(image.timestamp.toDate(), { addSuffix: true });

    // Function to navigate to edit page
    const handleEdit = (id) => {
        navigate(`/edit/${id}`);
    };


    // Function to handle image deletion
    const handleDelete = async (id, imageUrl, annotatedImageUrl) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this image?");
        if (confirmDelete) {
            try {
                if (imageUrl) {
                    const storageRef = ref(storage, imageUrl);
                    await deleteObject(storageRef);
                }

                if (annotatedImageUrl) {
                    const annotatedImageRef = ref(storage, annotatedImageUrl);
                    await deleteObject(annotatedImageRef);
                }

                await deleteDoc(doc(db, "images", id));
                navigate(-1);
            } catch (error) {
                console.error("Error deleting the image: ", error);
            }
        }
    };

    // Function to get profile picture of a user
    const getProfilePicture = async (uid) => {
        const docRef = doc(db, 'users', uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            return docSnap.data().profilePicture;
        } else {
            console.log('No such document!');
            return null;
        }
    };


    const closeModal = () => {
        setIsModalOpen(false); // Désactiver la modal

        setTimeout(() => {
            if (location.state?.backgroundLocation) {
                // Revenir à la page d'origine avant l'ouverture de la modal
                navigate(location.state.backgroundLocation.pathname + location.state.backgroundLocation.search, { replace: true });
            } else if (location.state?.from) {
                // Revenir à la page précédente
                navigate(location.state.from, { replace: true });
            } else if (document.referrer && !document.referrer.includes(window.location.host)) {
                // Si l'utilisateur vient d'un site externe (comme Google), revenir à la page d'exploration
                navigate('/explore', { replace: true });
            } else {
                // Si aucune page d'origine n'est trouvée et l'utilisateur n'a pas de referrer externe, revenir à 'explore'
                navigate('/explore', { replace: true });
            }
        }, 50);  // Ajoute un léger délai pour empêcher la réouverture immédiate
    };


    // Functions to handle mouse enter and leave events for annotated image
    const handleMouseEnter = () => {
        if (annotatedAvailable) {
            setShowAnnotated(true);
        }
    };

    const handleMouseLeave = () => {
        setShowAnnotated(false);
    };


    const resetTransformations = () => {
        setBrightness(100);
        setContrast(100);
        setSaturation(100);
    };




    return (
        <ImageNavigator setNavigateToNextImage={setNavigateToNextImage}
            setNavigateToPreviousImage={setNavigateToPreviousImage} >
            <div
                className={` modal-size ${isMinimized ? 'minimized' : ''}`}
                style={{ overflow: 'hidden', transition: 'height 0.3s ease' }}
                role="dialog"
                aria-modal="true"
                tabIndex={-1}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                ref={modalRef}
            >

                <div className="modal-size">

                    <Button className="absolute right-3 top-3 z-40 button-close" isIconOnly variant="light" onPress={closeModal}>
                        <Add size={30} className="rotate-45" />
                    </Button>

                    <div className="container-image-preview ">
                        <div className="imageContainer w-screen max-h-96 md:max-h-full" style={styles.imageContainer}>
                            <div className="max-h-[520vh] md:max-h-full" style={styles.imageWrapper} ref={imageRef}>
                                <div className="flex flex-col justify-between h-full w-full">
                                    {isMobile ? (
                                        <TransformWrapper
                                            onZoomChange={({ scale }) => setIsZoomed(scale > 1)}
                                            minScale={1}
                                            maxScale={5}
                                            defaultScale={1}
                                            defaultPositionX={0}
                                            defaultPositionY={0}

                                        >
                                            <TransformComponent
                                                wrapperStyle={{
                                                    width: "100%",
                                                    height: "100%",
                                                }}

                                                contentStyle={{
                                                    width: '100%',
                                                    height: '100%',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <img
                                                    src={imageUrl}
                                                    alt={image.name}
                                                    style={{
                                                        maxWidth: '100%',
                                                        maxHeight: '100%',
                                                        position: isZoomed ? 'absolute' : 'relative',
                                                        top: 0,
                                                        left: 0,
                                                        objectFit: 'contain', // Maintient l'image visible en entier
                                                    }}
                                                />
                                            </TransformComponent>
                                        </TransformWrapper>
                                    ) : (
                                        <div style={{
                                            ...styles.image,
                                            ...imageStyle,
                                        }}
                                            className="w-full relative group" >

                                            <img

                                                src={imageUrl}
                                                alt={image.metadata.description || 'Image description not available'} // Description alternative
                                                tabIndex={0} // Rendre l'image focusable
                                                role="img" // Rôle explicite pour les lecteurs d'écran
                                                aria-label={image.metadata.description || 'Image description not available'} // Étiquette pour VoiceOver
                                                style={{
                                                    ...styles.image,
                                                    ...imageStyle,
                                                }}
                                            />

                                            <div
                                                className={`absolute left-2 top-1/2 transform -translate-y-1/2 z-50 transition-opacity duration-300 ${isInactive ? 'opacity-0' : 'opacity-100'
                                                    }`}
                                            >
                                                <Button isIconOnly onClick={navigateToPreviousImage} variant="light">
                                                    <ArrowLeft2 size={30} />
                                                </Button>
                                            </div>

                                            <div
                                                className={`absolute right-2 top-1/2 transform -translate-y-1/2 z-50 transition-opacity duration-300 ${isInactive ? 'opacity-0' : 'opacity-100'
                                                    }`}
                                            >
                                                <Button isIconOnly onClick={navigateToNextImage} variant="light">
                                                    <ArrowRight2 size={30} />
                                                </Button>
                                            </div>
                                        </div>
                                    )}


                                    <div className="absolute flex flex-row p-2 bottom-0 right-0 gap-2">
                                        <Tooltip className="dark" content={showAnnotated ? t('showoriginal') : t('showannotated')}>
                                            <Switch
                                                className="purple-dark"
                                                checked={showAnnotated}
                                                onChange={toggleAnnotated}
                                                size="md"
                                                startContent={<Image size={16} variant="Bold" />}
                                                endContent={<Scanning size={16} variant="Bold" />}
                                                isDisabled={!annotatedAvailable}
                                            />
                                        </Tooltip>


                                        <div>
                                            <Tooltip className="dark" content="Settings">
                                                <Button isIconOnly variant="light" onPress={toggleSettings}>
                                                    <Setting4 size='24' />
                                                </Button>
                                            </Tooltip>
                                        </div>


                                        <div className="button-fullscreen">
                                            <Tooltip className="dark" content="Fullscreen">
                                                <Button variant="light" isIconOnly auto onClick={handleFullscreen}>
                                                    <Maximize3 className="text-foreground" size="24" />
                                                </Button>
                                            </Tooltip>
                                        </div>




                                    </div>

                                    {showSettings && (
                                        <div className="absolute right-2 bottom-12 flex flex-col bg-default-50 p-4 rounded-lg gap-2 w-64">

                                            <Slider
                                                label={`${t("brightness")}`}  // Affiche la valeur en pourcentage
                                                size="sm"
                                                maxValue={200}
                                                minValue={0}
                                                step={1}
                                                value={brightness}
                                                aria-label="Brightness"
                                                defaultValue={100}
                                                onChange={setBrightness}  // Utilise onValueChange pour mettre à jour l'état directement
                                                fillOffset={100}
                                            />

                                            <Slider
                                                label={t("contrast")}
                                                size="sm"
                                                maxValue={200}
                                                minValue={0}
                                                step={1}
                                                value={contrast}
                                                aria-label="Contrast"
                                                defaultValue={100}
                                                onChange={setContrast}  // Utilise onValueChange pour mettre à jour l'état directement
                                                fillOffset={100}
                                            />

                                            <Slider
                                                label={t("saturation")}
                                                size="sm"
                                                maxValue={200}
                                                minValue={0}
                                                step={1}
                                                value={saturation}
                                                aria-label="Saturation"
                                                defaultValue={100}
                                                onChange={setSaturation}  // Utilise onValueChange pour mettre à jour l'état directement
                                                fillOffset={100}
                                            />

                                            <Button variant='flat' size='sm' onClick={resetTransformations}>
                                                <Back size={20} />{t('Reset Modifications')}
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="py-4 md:py-10  md:px-6 px-2" style={styles.infoContainer}>
                            <ScrollShadow size={25} hideScrollBar >
                                <div className="flex flex-col gap-2 items-start mb-2">
                                    <div className="flex flex-row justify-between w-full">
                                        <div className="cursor-pointer flex gap-2" onClick={() => navigate('/profile/' + image.username)}>
                                            <Avatar
                                                size="lg"
                                                className="border border-default-100"
                                                showFallback
                                                src={profilePicture}
                                            />

                                            <div>
                                                <p>
                                                    {image.username}
                                                </p>

                                                <p className="text-default-400" >
                                                    {timeSincePosted}
                                                </p>
                                            </div>



                                        </div>
                                        <div className="flex flex-row items-center">
                                            {image.author === currentUser?.uid ? (
                                                <>
                                                    <Dropdown className="dark">
                                                        <DropdownTrigger>
                                                            <Button isIconOnly variant="light">
                                                                <Tooltip className="purple-dark" content={t("more")}>
                                                                    <More variant="Bold" />
                                                                </Tooltip>
                                                            </Button>
                                                        </DropdownTrigger>
                                                        <DropdownMenu variant="faded">
                                                            <DropdownItem key="edit"
                                                                startContent={<Edit2 size={18} variant="Bold" />}
                                                                onClick={() => handleEdit(imageId)}
                                                            >
                                                                {t("edit")}
                                                            </DropdownItem>
                                                            <DropdownItem key="download"
                                                                startContent={<ImportCurve size={18} variant="Bold" />}
                                                                onClick={() => handleDownload(imageUrl)}
                                                            >
                                                                {t("download")}
                                                            </DropdownItem>
                                                            <DropdownItem key="delete"
                                                                startContent={<Trash size={18} variant="Bold" />}
                                                                onClick={() => handleDelete(imageId, image.imageUrl, image.annotatedImageUrl)}
                                                                color="danger"
                                                                className="text-danger"
                                                            >
                                                                {t("setupManager.delete")}
                                                            </DropdownItem>
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                </>
                                            ) : (
                                                <div>

                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <Divider className="my" />
                                    <div className="flex flex-row gap-2 items-center">
                                        <LikeButton
                                            likedBy={currentUser?.username}
                                            likedById={currentUser?.uid}
                                            recipientId={image.author}
                                            imageId={image.id}
                                            initialLikes={image.like} />
                                        {isMobile ? (
                                            <Button isIconOnly onClick={handleShare} variant="light">

                                                <ExportCurve size={24} />
                                            </Button>
                                        ) : (
                                            <Button isIconOnly onClick={handleCopyUrl} variant="light">

                                                <ExportCurve size={24} />
                                            </Button>
                                        )}


                                        {image.metadata.CommercialLink && (
                                            <Button
                                                endContent={<ExportSquare size={18} />}
                                                color="primary"
                                                size="sm"

                                                onClick={() => {
                                                    const url = image.metadata.CommercialLink.startsWith('http')
                                                        ? image.metadata.CommercialLink
                                                        : `https://${image.metadata.CommercialLink}`;
                                                    window.open(url, '_blank');
                                                }}

                                            >
                                                Purchase
                                            </Button>
                                        )}


                                    </div>

                                </div>
                                <div className="w-screen" style={styles.chipContainer}>
                                    <div className="flex flex-col gap-4 w-full">
                                        {image.metadata && image.metadata.Description && (
                                            <div>
                                                <p tabIndex={0} aria-label={`Description of image: ${image.metadata.Description}`}>{image.metadata.Description}</p>

                                            </div>

                                        )}
                                        {/*  
                        <Tutorial objectName={image.objects} />*/}

                                        <Divider className="my" />

                                        <CommentSection imageId={image.id} imageOwnerId={image.author} inputRef={commentInputRef} />

                                        <Divider className="my" />
                                        {image.metadata && image.metadata.LocationName && (
                                            <div>
                                                <h2 style={styles.metadataGroupName}> {t('location')}</h2>
                                                <p>{image.metadata.LocationName}</p>
                                            </div>
                                        )}
                                        {image.metadata && image.metadata.ObservationDate && (
                                            <div>
                                                <h2 style={styles.metadataGroupName}> {t('observationdate')}</h2>
                                                <p>{image.metadata.ObservationDate.toDate().toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</p>
                                            </div>
                                        )}
                                        <div className="w-full overflow-auto flex flex-col gap-4">
                                            {image.objects && image.objects.length > 0 && (
                                                <div>
                                                    <h2 style={styles.metadataGroupName}>{t('object')}</h2>
                                                    <div className="flex flex-row flex-wrap w-full">
                                                        {image.objects.map((obj, objIndex) => (
                                                            <Link
                                                                key={objIndex}
                                                                to={`/search?q=${encodeURIComponent(obj)}`}
                                                                className="purple-dark"
                                                            >
                                                                <CustomChip onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                                    <div className="flex flex-row gap-1 min-w-0 overflow-hidden">
                                                                        {obj}
                                                                        {/* 
                                                        <AstronomicalEvent objectName={obj} />
                                                        */}
                                                                    </div>
                                                                </CustomChip>
                                                            </Link>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                            {image.metadata && image.metadata.Telescope && image.metadata.Telescope.length > 0 && (
                                                <div>
                                                    <h2 style={styles.metadataGroupName}>{t('telescope')}</h2>
                                                    <div className="flex flex-row flex-wrap w-full">
                                                        {image.metadata.Telescope.map((obj, objIndex) => (
                                                            <Link
                                                                key={objIndex}
                                                                to={`/search?q=${encodeURIComponent(obj)}`}
                                                            >
                                                                <CustomChip>
                                                                    {obj}
                                                                </CustomChip>
                                                            </Link>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                            {image.metadata && image.metadata.Mount && image.metadata.Mount.length > 0 && (
                                                <div>
                                                    <h2 style={styles.metadataGroupName}>{t('mount')}</h2>
                                                    <div className="flex flex-row flex-wrap w-full">
                                                        {image.metadata.Mount.map((filter, filterIndex) => (
                                                            <Link
                                                                key={filterIndex}
                                                                to={`/search?q=${encodeURIComponent(filter)}`}
                                                            >
                                                                <CustomChip>
                                                                    {filter}
                                                                </CustomChip>
                                                            </Link>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                            {image.metadata && image.metadata.Camera && image.metadata.Camera.length > 0 && (
                                                <div>
                                                    <h2 style={styles.metadataGroupName}>{t('camera')}</h2>
                                                    <div className="flex flex-row flex-wrap w-full">
                                                        {image.metadata.Camera.map((filter, filterIndex) => (
                                                            <Link
                                                                key={filterIndex}
                                                                to={`/search?q=${encodeURIComponent(filter)}`}
                                                            >
                                                                <CustomChip>
                                                                    {filter}
                                                                </CustomChip>
                                                            </Link>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                            {image.metadata && image.metadata.Filter && image.metadata.Filter.length > 0 && (
                                                <div>
                                                    <h2 style={styles.metadataGroupName}>{t('filter')}</h2>
                                                    <div className="flex flex-row flex-wrap w-full">
                                                        {image.metadata.Filter.map((filter, filterIndex) => (
                                                            <Link
                                                                key={filterIndex}
                                                                to={`/search?q=${encodeURIComponent(filter)}`}
                                                            >
                                                                <CustomChip>
                                                                    {filter}
                                                                </CustomChip>
                                                            </Link>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                            {image.metadata && image.metadata.Software && image.metadata.Software.length > 0 && (
                                                <div>
                                                    <h2 style={styles.metadataGroupName}>{t('software')}</h2>
                                                    <div className="flex flex-row flex-wrap w-full">
                                                        {image.metadata.Software.map((filter, filterIndex) => (
                                                            <Link
                                                                key={filterIndex}
                                                                to={`/search?q=${encodeURIComponent(filter)}`}
                                                            >
                                                                <CustomChip>
                                                                    {filter}
                                                                </CustomChip>
                                                            </Link>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                            {image.metadata && image.metadata.Accessory && image.metadata.Accessory.length > 0 && (
                                                <div>
                                                    <h2 style={styles.metadataGroupName}>{t('accessory')}</h2>
                                                    <div className="flex flex-row flex-wrap w-full">
                                                        {image.metadata.Accessory.map((filter, filterIndex) => (
                                                            <Link
                                                                key={filterIndex}
                                                                to={`/search?q=${encodeURIComponent(filter)}`}
                                                            >
                                                                <CustomChip>
                                                                    {filter}
                                                                </CustomChip>
                                                            </Link>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                        </div>


                                        <div >
                                            {image.objects && image.objects.length > 0 && (
                                                <SimilarImages objectName={image.objects[0]} currentImageId={imageId} />
                                            )}
                                        </div>



                                        <UserImages userId={image.author} currentImageId={imageId} />


                                    </div>

                                </div>



                            </ScrollShadow>
                        </div>
                    </div>
                </div>
            </div>
        </ImageNavigator>


    );
};

const styles = {
    imageContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    imageWrapper: {
        backgroundColor: "black",
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",

        overflow: "hidden",
    },
    image: {
        width: "100%",
        height: "100%",
        objectFit: "contain",
    },
    infoContainer: {
        flex: 1,
        display: "flex",
        height: "auto",
        flexDirection: "column",
        overflow: "auto",
        height: "100%",
    },
    chipContainer: {
        display: "flex",
        flexWrap: "wrap",
        marginTop: "10px",
        flexDirection: "column",
        width: "100%",
    },
    metadataGroupName: {
        fontSize: '14px',
        color: 'hsl(var(--nextui-default-500))',
        textTransform: "uppercase",
        fontWeight: '500',
        marginBottom: '0.2rem',
    }
};

export default ImageDetails;