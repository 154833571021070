import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { collection, query, orderBy, getDocs, where } from "firebase/firestore";
import { db } from "../components/Firebase/firebaseConfig";

const ImageNavigator = ({ children, setNavigateToNextImage, setNavigateToPreviousImage }) => {
    const { imageId } = useParams();
    const navigate = useNavigate();
    const [images, setImages] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(null);

    // Fonction pour récupérer la liste des images triées par timestamp dans Firestore
    const fetchImages = async () => {
        try {
            const imagesCollection = collection(db, "images");
            const imagesQuery = query(
                imagesCollection,
                where("state", "==", true), // Filtre les images actives
                where("publish", "==", true), // Filtre les images publiées
                orderBy("timestamp", "desc") // Trier par timestamp descendant
            );
            const snapshot = await getDocs(imagesQuery);
            const imageList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setImages(imageList);
        } catch (error) {
            console.error("Error fetching images:", error);
        }
    };

    // Charger les images au montage du composant
    useEffect(() => {
        fetchImages();
    }, []);

    // Mettre à jour l'image actuelle lorsque l'imageId change
    useEffect(() => {
        if (images.length > 0) {
            const index = images.findIndex(img => img.id === imageId);
            if (index !== -1) {
                setCurrentIndex(index);
            }
        }
    }, [imageId, images]);

    // Fonction pour naviguer vers l'image suivante
    const navigateToNextImage = () => {
        if (currentIndex !== null && currentIndex < images.length - 1) {
            const nextImageId = images[currentIndex + 1].id;
            navigate(`/image/${nextImageId}`);
        }
    };

    // Fonction pour naviguer vers l'image précédente
    const navigateToPreviousImage = () => {
        if (currentIndex !== null && currentIndex > 0) {
            const prevImageId = images[currentIndex - 1].id;
            navigate(`/image/${prevImageId}`);
        }
    };

    // Ajouter un écouteur pour les touches fléchées
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'ArrowRight') {
                navigateToNextImage();
            } else if (e.key === 'ArrowLeft') {
                navigateToPreviousImage();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        // Nettoyer l'écouteur lors du démontage du composant
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [currentIndex, images]);

    // Transmettre les fonctions de navigation au parent
    useEffect(() => {
        setNavigateToNextImage(() => navigateToNextImage);
        setNavigateToPreviousImage(() => navigateToPreviousImage);
    }, [currentIndex, images]);

    return <>{children}</>;
};

export default ImageNavigator;
