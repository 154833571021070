import React, { useState, useEffect, useContext, useCallback, useRef } from "react";
import { collection, doc, updateDoc, addDoc, deleteDoc, query, where, getDocs, onSnapshot, getDoc } from "firebase/firestore";
import { Modal, ModalContent, ModalHeader, ModalBody, Link, ModalFooter, User, Skeleton } from "@nextui-org/react";
import { db } from "../components/Firebase/firebaseConfig";
import UserContext from "../components/UserContext";
import { Heart } from "iconsax-react";
import { useSpring, animated } from '@react-spring/web';
import { addLikeNotification } from "../components/Firebase/addLikeNotification";

function LikeButton({ likedBy, likedById, imageId, initialLikes, recipientId }) {
    const [likes, setLikes] = useState(initialLikes);
    const [liked, setLiked] = useState(false);
    const [likedUsers, setLikedUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { currentUser } = useContext(UserContext);
    const heartRef = useRef(null);

    useEffect(() => {
        if (!currentUser) return;

        const fetchUserLike = async () => {
            const q = query(collection(db, "likes"), where("userId", "==", currentUser.uid), where("imageId", "==", imageId));
            const likesSnapshot = await getDocs(q);
            if (!likesSnapshot.empty) setLiked(true);
        };

        const fetchLikedUsers = async () => {
            const q = query(collection(db, "likes"), where("imageId", "==", imageId));
            const likesSnapshot = await getDocs(q);

            const userIds = likesSnapshot.docs.map(doc => doc.data().userId);
            const users = await Promise.all(
                userIds.map(async (uid) => {
                    const userDoc = await getDoc(doc(db, "users", uid));
                    if (userDoc.exists()) {
                        const userData = userDoc.data();
                        return {
                            uid,
                            username: userData.username,
                            profilePicture: userData.profilePicture,
                            description: userData.description
                        };
                    }
                    return null;
                })
            );

            setLikedUsers(users.filter(user => user !== null));
            setIsLoading(false); // Data is now loaded

        };

        fetchUserLike();
        fetchLikedUsers();
    }, [currentUser, imageId]);

    useEffect(() => {
        const imageRef = doc(db, "images", imageId);
        const unsubscribe = onSnapshot(imageRef, (doc) => {
            if (doc.exists()) setLikes(doc.data().like);
        });
        return () => unsubscribe();
    }, [imageId]);

    const handleLike = useCallback(async () => {
        if (!currentUser) {
            alert("Please log in to like images.");
            return;
        }

        const likesCollection = collection(db, "likes");
        const imageRef = doc(db, "images", imageId);
        let newLikes = likes;

        try {
            if (liked) {
                const q = query(likesCollection, where("userId", "==", currentUser.uid), where("imageId", "==", imageId));
                const likesSnapshot = await getDocs(q);
                if (!likesSnapshot.empty) {
                    await deleteDoc(doc(db, "likes", likesSnapshot.docs[0].id));
                    newLikes = Math.max(0, likes - 1);
                    setLiked(false);
                }
            } else {
                await addDoc(likesCollection, { userId: currentUser.uid, imageId });
                newLikes = likes + 1;
                setLiked(true);

                if (recipientId) {
                    await addLikeNotification(likedBy, likedById, imageId, recipientId);
                }
            }

            if (newLikes !== likes) await updateDoc(imageRef, { like: newLikes });
        } catch (error) {
            console.error("Error updating like:", error);
        }
    }, [currentUser, liked, likes, imageId, likedBy, likedById, recipientId]);

    const [springProps, setSpringProps] = useSpring(() => ({ transform: 'scale(1)' }));

    const handleIconClick = () => {
        if (!currentUser) {
            alert("Please log in to like images.");
            return;
        }

        // Trigger async like update
        handleLike();

        // Optimistic UI update for animation
        setLiked((prevLiked) => !prevLiked);
        setLikes((prevLikes) => (liked ? Math.max(0, prevLikes - 1) : prevLikes + 1));

        setSpringProps({ transform: 'scale(1.4)' });
        setTimeout(() => setSpringProps({ transform: 'scale(1)' }), 100);
    };

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (
        <>


            <div
                tabIndex={0}
                role="button"
                aria-pressed={liked}
                aria-label={`Like this post, ${likes} likes`}
                className="dark flex flex-row gap-1 items-center p-1 cursor-pointer mr-1"
                onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') handleIconClick();
                }}
            >
                <animated.div style={springProps} onClick={handleIconClick}>
                    <Heart
                        ref={heartRef}
                        variant={liked ? "Bold" : "Outline"}
                        size="24"
                        style={{ cursor: 'pointer', color: liked ? 'hsl(var(--nextui-danger-400))' : 'hsl(var(--nextui-default-700))' }}
                    />
                </animated.div>

                <p className="font-medium hover:underline" onClick={handleOpenModal}>
                    {likes} Likes
                </p>
            </div>

            <Modal scrollBehavior="inside" isOpen={isModalOpen} onClose={handleCloseModal} className="dark">
                <ModalContent>
                    <ModalHeader>Liked by</ModalHeader>
                    <ModalBody>
                        <div className="flex flex-col items-start gap-4">
                            {likedUsers.map((user, index) => (
                                <Link key={index} href={`/profile/${user.username || "Unknown"}`} className="text-sm cursor-pointer hover:underline text-default-700 font-medium">
                                    <User
                                        avatarProps={{
                                            src: user.profilePicture
                                        }}
                                        name={user.username}

                                    />
                                </Link>
                            ))}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}

export default LikeButton;
