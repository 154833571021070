import React, { useState, useContext, useEffect } from "react";
import { Input, Button, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter } from "@nextui-org/react";
import { useDisclosure } from "@nextui-org/react";
import { signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { auth } from "./Firebase/firebaseConfig";
import { getFirebaseErrorMessage } from "./Firebase/firebaseErrors";
import { Link, useNavigate } from "react-router-dom";
import StarmazeSVG from "../assets/images/starmaze.svg"; // Import the default SVG
import { CloseCircle } from "iconsax-react"; // Import de l'icône de cœur
import { useTranslation } from "react-i18next"; // Import i18next
import UserContext from "./UserContext";

const Login = ({ onLogin }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [resetEmail, setResetEmail] = useState(""); // Nouvel état pour l'email de réinitialisation
    const [error, setError] = useState("");
    const { isOpen, onOpen, onOpenChange } = useDisclosure();
    const { isOpen: isResetOpen, onOpen: onResetOpen, onOpenChange: onResetOpenChange } = useDisclosure(); // Contrôles de la modale de réinitialisation
    const [isLoading, setIsLoading] = useState(false); // State pour gérer l'état de chargement
    const navigate = useNavigate();
    const { t } = useTranslation(); // Utilisation de useTranslation pour obtenir la fonction de traduction
    const { currentUser } = useContext(UserContext);

    useEffect(() => {
        console.log('Utilisateur actuel dans UserContext :', currentUser);
    }, [currentUser]);


    const handleLogin = async () => {
        console.log("Début du processus de login");
        setIsLoading(true); // Début du chargement
        setError("");
        try {
            console.log("Tentative de connexion avec l'email :", email);
            await signInWithEmailAndPassword(auth, email, password);
            console.log("Connexion réussie");

            setIsLoading(false); // Fin du chargement
            console.log("Appel à la fonction onLogin");
            if (typeof onLogin === 'function') {
                onLogin();
                console.log("La fonction onLogin s'est exécutée sans erreur");
            } else {
                console.warn("onLogin n'est pas une fonction valide");
            }

            console.log("La fonction onLogin s'est exécutée sans erreur");

            console.log("Appel à la fonction onLogin exécuté");

            navigate('/profile', { replace: true });
            console.log("Redirection vers /profile réussie");

        } catch (error) {
            console.error("Erreur complète lors de la connexion :", error);
            console.log("Message d'erreur :", error.message);
            console.log("Code d'erreur :", error.code);
            console.log("Stack trace :", error.stack);

            const errorMessage = getFirebaseErrorMessage(error.code) || "Une erreur inconnue s'est produite";
            setError(errorMessage);
            setIsLoading(false);
            onOpen();
            console.log("Affichage de la modale d'erreur avec le message :", errorMessage);
        }

    };

    const handleResetPassword = async () => {
        setError("");
        try {
            await sendPasswordResetEmail(auth, resetEmail);
            onResetOpenChange(false);
            setResetEmail("");
        } catch (error) {
            const errorMessage = getFirebaseErrorMessage(error.code);
            setError(errorMessage);
            onOpen();
        }
    };

    return (
        <>
            <div className="flex flex-col max-w-xl m-auto gap-3 mt-20 min-h-screen" >
                {/* <img src={StarmazeSVG} alt="Search illustration" className="w-16 my-6 m-auto" /> */}
                <h1 className="text-3xl font-bold mb-4 text-center">{t('loginTitle')}</h1>

                <Input
                    clearable
                    underlined
                    label={t('emailLabel')}
                    placeholder={t('emailPlaceholder')}
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    size="lg"
                />

                <Input
                    clearable
                    underlined
                    label={t('passwordLabel')}
                    placeholder={t('passwordPlaceholder')}
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    size="lg"
                />


                <Link className="text-right" href="#">
                    <a className="text-default-500 underline " onClick={onResetOpen}>
                        {t('forgotPassword')}
                    </a>
                </Link>

                <Button isLoading={isLoading} size="lg" color="primary" onPress={handleLogin}>   {t('loginButton')}
                </Button>

                <p className="text-center">{t('or')}</p>

                <Link to="/signup">
                    <Button size="lg" className="w-full">{t('signUpButton')}</Button>
                </Link>



            </div>
            <Modal hideCloseButton isOpen={isOpen} onOpenChange={onOpenChange} className="purple-dark text-foreground text-center">
                <ModalContent>
                    {(onClose) => (
                        <>
                            <CloseCircle color="#FF697B" className="text-foreground m-auto mt-8" variant="Bulk" size="80" />
                            <ModalHeader className="m-auto mt-0"  >Login Error</ModalHeader>
                            <ModalBody>
                                <p>{error}</p>
                            </ModalBody>
                            <ModalFooter>
                                <Button className="w-full" color="primary" onPress={onClose}>
                                    Ok
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>

            <Modal isOpen={isResetOpen} onOpenChange={onResetOpenChange} className="purple-dark text-foreground">
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader>{t('resetPasswordTitle')}</ModalHeader>
                            <ModalBody>
                                <p>{t('resetPasswordMessage')}</p>
                                <Input
                                    clearable
                                    underlined
                                    label={t('emailLabel')}
                                    placeholder={t('emailPlaceholder')}
                                    type="email"
                                    value={resetEmail}
                                    onChange={(e) => setResetEmail(e.target.value)}
                                />
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onPress={handleResetPassword}>
                                    {t('validateButton')}
                                </Button>
                                <Button auto flat onPress={onClose}>
                                    {t('cancelButton')}
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    );
};

export default Login;
