import React, { useEffect, useState } from 'react';
import { db } from "../Firebase/firebaseConfig";
import { collection, getDocs } from 'firebase/firestore';
import { useParams, useNavigate } from 'react-router-dom';
import { Button } from "@nextui-org/react";
import { ArrowLeft2 } from 'iconsax-react';

const ArticleDetail = () => {
    const { title } = useParams(); // Récupère le titre depuis l'URL
    const [article, setArticle] = useState(null);
    const navigate = useNavigate(); // Initialise useNavigate

    useEffect(() => {
        const fetchArticle = async () => {
            const articlesCollection = collection(db, 'articles');
            const articlesSnapshot = await getDocs(articlesCollection);
            const articlesList = articlesSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));

            // Trouver l'article correspondant au titre dans les articles récupérés
            const foundArticle = articlesList.find(article => {
                return article.title.replace(/\s+/g, '-').toLowerCase() === title; // Comparaison avec le slug
            });

            if (foundArticle) {
                setArticle(foundArticle);
            } else {
                console.log("Article non trouvé !");
            }
        };

        fetchArticle();
    }, [title]);

    if (!article) return <></>;

    const paragraphs = article.description.split('\n'); // Supposons que les paragraphes sont séparés par des sauts de ligne

    // Fonction pour gérer le clic sur le bouton "Retour"
    const handleBackClick = () => {
        navigate(-1); // Retourne à la page précédente
    };

    // Fonction pour formater la date
    const formatDate = (date) => {
        return date.toLocaleDateString('fr-FR', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        });
    };

    return (
        <div className=' m-auto md:w-1/2 w-full px-2'>
            <Button
                startContent={<ArrowLeft2 size={20} />}
                variant='light'
                onClick={handleBackClick} // Appelle la fonction de retour
                isIconOnly
            >
            </Button>
            <h1 className='text-3xl font-bold'>{article.title}</h1>
            <p>{formatDate(article.createdAt.toDate())}</p> {/* Utilisation de la fonction formatDate */}

            <div className='my-8 flex flex-col gap-2'>
                <img src={article.firstImage} alt={article.title} className='rounded' />
                <p className='text-default-600'>{article.imageCaption}</p>
            </div>

            {paragraphs.map((para, index) => (
                <p key={index} className='text-lg leading-7 mb-4'>{para}</p> // Crée un paragraphe pour chaque segment
            ))}

            {/* Section pour afficher les sources */}
            <div className="mt-6">
                <div className="mt-4">
                    <h3 className="text-xl font-bold">Journal Info</h3>
                    <div dangerouslySetInnerHTML={{ __html: article.journalInfo }} />
                </div>

                <div className="mt-4">
                    <h3 className="text-xl font-bold">More Info</h3>
                    <div dangerouslySetInnerHTML={{ __html: article.moreInfo }} />
                </div>
            </div>
        </div>
    );
};

export default ArticleDetail;
