import React, { useState, useEffect, useContext } from 'react';
import { collection, getDocs, deleteDoc, doc, query, where, setDoc } from "firebase/firestore";
import { db } from "./Firebase/firebaseConfig";
import UserContext from "./UserContext";
import { formatDistanceToNow, isValid } from 'date-fns';
import { Link, useNavigate } from 'react-router-dom';
import { useTransition, animated } from '@react-spring/web';
import LoginModal from "./LoginModal";
import { Button } from "@nextui-org/react";
import { useTranslation } from 'react-i18next';


const Notifications = () => {
    const { t } = useTranslation(); // Utilisation du hook de traduction
    const navigate = useNavigate();
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true);
    const { currentUser } = useContext(UserContext);
    const [isModalVisible, setIsModalVisible] = useState(false);


    useEffect(() => {
        if (!currentUser) {
            setIsModalVisible(true);
        }
    }, [currentUser]);

    useEffect(() => {
        const fetchNotifications = async () => {
            if (!currentUser) return;

            setLoading(true);
            try {
                const notificationsCollection = collection(db, "notifications");
                const q = query(notificationsCollection, where("recipientId", "==", currentUser.uid));
                const notificationsSnapshot = await getDocs(q);
                const notificationsData = notificationsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setNotifications(notificationsData);
            } catch (error) {
                console.error("Error fetching notifications: ", error);
            } finally {
                setLoading(false);
            }
        };
        fetchNotifications();
    }, [currentUser]);

    const deleteNotification = async (id) => {
        setNotifications(prevNotifications => prevNotifications.filter(notification => notification.id !== id));
        try {
            await deleteDoc(doc(db, "notifications", id));
        } catch (error) {
            console.error("Error deleting notification: ", error);
        }
    };

    const deleteAllNotifications = async () => {
        setNotifications([]);
        try {
            const notificationsCollection = collection(db, "notifications");
            const q = query(notificationsCollection, where("recipientId", "==", currentUser.uid));
            const notificationsSnapshot = await getDocs(q);
            const deletePromises = notificationsSnapshot.docs.map(doc => deleteDoc(doc.ref));
            await Promise.all(deletePromises);
        } catch (error) {
            console.error("Error deleting notifications: ", error);
        }
    };

    const formatNotificationDate = (timestamp) => {
        const date = new Date(timestamp);
        return isValid(date) ? formatDistanceToNow(date, { addSuffix: true }) : t('invalidDate');
    };

    const transitions = useTransition(notifications, {
        keys: notification => notification.id,
        from: { opacity: 0, transform: 'translateY(-10px)' },
        enter: { opacity: 1, transform: 'translateY(0)' },
        leave: { opacity: 0, transform: 'translateY(-10px)' },
        config: { duration: 200 },
    });

    return (
        <div className="w-full bg-default-50 shadow-lg rounded-lg">
            <div className="p-4">

                <div className='flex justify-between items-start'>
                    <h1 className="text-lg font-bold">{t('notifications')}</h1>

                    <Button size='sm' variant='light' onClick={deleteAllNotifications} >{t('markAsRead')}</Button>

                </div>

                <div className="mt-2 flex flex-col gap-2">
                    {notifications.length === 0 && !loading && (
                        <div className="flex flex-col items-center justify-center mt-4">
                            <p className="text-md text-default-400 text-center w-64">{t('quietHere')}</p>
                        </div>
                    )}
                    {transitions((style, notification) => (
                        <animated.div key={notification.id} style={style}>
                            <div className={`notification ${notification.read ? 'read' : 'unread'}`} css={{ mb: "$8" }}>

                                <Link to={`/image/${notification.imageId}`} onClick={() => deleteNotification(notification.id)} className="p-2 hover:bg-default-100 rounded-lg flex items-center gap-2 w-full">

                                    <div className="notification-dot" />

                                    <div>
                                        <p>{notification.message}</p>
                                        <p className='text-sm text-default-400'>{formatNotificationDate(notification.timestamp)}</p>
                                    </div>

                                </Link>

                            </div>
                        </animated.div>
                    ))}
                </div>
            </div>

            <LoginModal isOpen={isModalVisible} onClose={() => setIsModalVisible(false)} />
        </div >
    );
};

export default Notifications;