import React, { useContext, useState, useEffect } from "react";
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell, Pagination, Spinner, Link, User } from "@nextui-org/react";
import { useAsyncList } from "@react-stately/data";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "./Firebase/firebaseConfig";
import UserContext from "./UserContext";
import { Rank } from "iconsax-react";
import cosmicShardIcon from "../assets/images/gemstone.png"; // Import the image

function Ranking() {
    const { currentUser } = useContext(UserContext);
    const [currentUserRank, setCurrentUserRank] = useState(null);
    const [currentUserCosmicShards, setCurrentUserCosmicShards] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20;
    const CACHE_KEY = "rankingCache";
    const CACHE_EXPIRY_DAYS = 3;

    const calculateCosmicShards = (user) => {
        const followerScore = user.followerCount * 0.25;
        const imageScore = user.imageCount * 0.20;
        const likesReceivedScore = user.totalLikes * 0.20;
        const likesGivenScore = user.likesGiven * 0.15;
        const commentsGivenScore = user.commentsGiven * 0.20;
        return (followerScore + imageScore + likesReceivedScore + likesGivenScore + commentsGivenScore) / 2;
    };

    const isCacheExpired = (timestamp) => {
        const now = new Date().getTime();
        const cacheAge = now - timestamp;
        return cacheAge > CACHE_EXPIRY_DAYS * 24 * 60 * 60 * 1000;
    };

    const loadRankingData = async () => {
        const usersData = [];
        const querySnapshot = await getDocs(collection(db, "users"));
        querySnapshot.forEach((doc) => {
            const data = doc.data();
            if (data.username) {
                usersData.push({ ...data, key: doc.id });
            }
        });

        await Promise.all(usersData.map(async (user) => {
            const subscriptionsRef = collection(db, "subscriptions");
            const q = query(subscriptionsRef, where("subscribedToId", "==", user.key));
            const subscriptionsSnapshot = await getDocs(q);
            user.followerCount = subscriptionsSnapshot.size;

            const imagesRef = collection(db, "images");
            const imgQuery = query(imagesRef, where("author", "==", user.key));
            const imagesSnapshot = await getDocs(imgQuery);
            user.imageCount = imagesSnapshot.size;

            let totalLikes = 0;
            imagesSnapshot.forEach((imageDoc) => {
                const imageData = imageDoc.data();
                totalLikes += imageData.like || 0;
            });
            user.totalLikes = totalLikes;

            const likesRef = collection(db, "likes");
            const likesQuery = query(likesRef, where("userId", "==", user.key));
            const likesSnapshot = await getDocs(likesQuery);
            user.likesGiven = likesSnapshot.size;

            const commentsRef = collection(db, "comments");
            const commentsQuery = query(commentsRef, where("userId", "==", user.key));
            const commentsSnapshot = await getDocs(commentsQuery);
            user.commentsGiven = commentsSnapshot.size;

            user.cosmicShards = calculateCosmicShards(user);

            if (user.key === currentUser.uid) {
                setCurrentUserCosmicShards(user.cosmicShards);
            }
        }));

        usersData.sort((a, b) => b.cosmicShards - a.cosmicShards);
        usersData.forEach((user, index) => {
            user.rank = index + 1;
        });

        return usersData;
    };

    const list = useAsyncList({
        async load() {
            const cachedData = JSON.parse(localStorage.getItem(CACHE_KEY));
            if (cachedData && !isCacheExpired(cachedData.timestamp)) {
                const rankIndex = cachedData.usersData.findIndex((user) => user.key === currentUser.uid);
                if (rankIndex !== -1) {
                    setCurrentUserRank(rankIndex + 1);
                    setCurrentPage(Math.ceil((rankIndex + 1) / itemsPerPage));
                    setCurrentUserCosmicShards(cachedData.usersData[rankIndex].cosmicShards);
                }
                return { items: cachedData.usersData };
            }

            const usersData = await loadRankingData();
            const rankIndex = usersData.findIndex((user) => user.key === currentUser.uid);
            if (rankIndex !== -1) {
                setCurrentUserRank(rankIndex + 1);
                setCurrentPage(Math.ceil((rankIndex + 1) / itemsPerPage));
            }

            localStorage.setItem(
                CACHE_KEY,
                JSON.stringify({ usersData, timestamp: new Date().getTime() })
            );

            return { items: usersData };
        },
    });

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const paginatedUsers = list.items.slice(startIndex, startIndex + itemsPerPage);

    return (
        <div className="min-h-screen">

            {currentUserRank && (
                <>
                    <div className="flex gap-2 items-center mb-4">
                        <Rank size={34} variant="Bold" />
                        <h1 className="text-3xl font-bold">Ranking</h1>
                    </div>

                    <div className="items-center flex gap-4 mb-4 p-6 bg-primary-50 rounded-lg ">
                        <div className=" px-2" >
                            <img src={cosmicShardIcon} alt="Cosmic Shard Icon" className=" w-20 h-20  current-badge" />
                        </div>

                        <div>
                            <p className="text-primary-800 text-lg font-medium">
                                Ranked <span className="font-bold">{currentUserRank}</span> with <span className="font-bold">{currentUserCosmicShards?.toFixed(0)}</span> Cosmic Shards.
                            </p>
                            <p className="text-primary-800 font-medium">
                                Earn more by sharing, liking, and commenting!
                            </p>
                        </div>
                    </div>
                </>
            )}

            <Table
                color="default"
                aria-label="Users table with client-side sorting"
                sortDescriptor={list.sortDescriptor}
                onSortChange={list.sort}
                selectionMode="single"
                defaultSelectedKeys={currentUser ? [currentUser.uid] : []}
            >
                <TableHeader>
                    <TableColumn className="uppercase" key="rank" allowsSorting>Rank</TableColumn>
                    <TableColumn className="uppercase" key="username" allowsSorting>Username</TableColumn>
                    <TableColumn className="uppercase" key="cosmicShards" allowsSorting>Cosmic Shards</TableColumn>
                </TableHeader>
                <TableBody items={paginatedUsers} isLoading={list.isLoading} loadingContent={<Spinner />}>
                    {(user) => (
                        <TableRow key={user.key} isSelected={user.key === currentUser?.uid}>
                            <TableCell>{user.rank}</TableCell>
                            <TableCell>
                                <Link href={`/profile/${user.username}`} className="text-white hover:underline">
                                    <User avatarProps={{ src: user.profilePicture }} name={user.username} />
                                </Link>
                            </TableCell>
                            <TableCell>
                                <div className="flex items-center gap-1 h-full">
                                    <img src={cosmicShardIcon} alt="Cosmic Shard Icon" className="w-6 h-6 mr-1" />
                                    <span className="text-md font-medium text-primary-50">{(user.cosmicShards || 0).toFixed(0)}</span>
                                </div>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>

            <Pagination
                showControls
                className="mt-2 items-center justify-center flex"
                variant="light"
                total={Math.ceil(list.items.length / itemsPerPage)}
                initialPage={currentPage}
                onChange={handlePageChange}
                page={currentPage}
            />
        </div>
    );
}

export default Ranking;
