import { useEffect, useState, useContext } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "./Firebase/firebaseConfig";
import UserContext from "./UserContext";

function useUserRank(userId = null) {
    const { currentUser } = useContext(UserContext);
    const [userRank, setUserRank] = useState(null);
    const [cosmicShards, setCosmicShards] = useState(null);
    const [loading, setLoading] = useState(true);
    const CACHE_KEY = "userRankCache";
    const CACHE_EXPIRY_DAYS = 3;

    const calculateCosmicShards = (user) => {
        const followerScore = user.followerCount * 0.25;
        const imageScore = user.imageCount * 0.20;
        const likesReceivedScore = user.totalLikes * 0.20;
        const likesGivenScore = user.likesGiven * 0.15;
        const commentsGivenScore = user.commentsGiven * 0.20;
        return (followerScore + imageScore + likesReceivedScore + likesGivenScore + commentsGivenScore) / 2;
    };

    const isCacheExpired = (timestamp) => {
        const now = new Date().getTime();
        const cacheAge = now - timestamp;
        return cacheAge > CACHE_EXPIRY_DAYS * 24 * 60 * 60 * 1000;
    };

    const fetchUserRankData = async () => {
        const usersData = [];
        const querySnapshot = await getDocs(collection(db, "users"));

        querySnapshot.forEach((doc) => {
            const data = doc.data();
            if (data.username) {
                usersData.push({ ...data, key: doc.id });
            }
        });

        await Promise.all(usersData.map(async (user) => {
            const subscriptionsRef = collection(db, "subscriptions");
            const q = query(subscriptionsRef, where("subscribedToId", "==", user.key));
            const subscriptionsSnapshot = await getDocs(q);
            user.followerCount = subscriptionsSnapshot.size;

            const imagesRef = collection(db, "images");
            const imgQuery = query(imagesRef, where("author", "==", user.key));
            const imagesSnapshot = await getDocs(imgQuery);
            user.imageCount = imagesSnapshot.size;

            let totalLikes = 0;
            imagesSnapshot.forEach((imageDoc) => {
                const imageData = imageDoc.data();
                totalLikes += imageData.like || 0;
            });
            user.totalLikes = totalLikes;

            const likesRef = collection(db, "likes");
            const likesQuery = query(likesRef, where("userId", "==", user.key));
            const likesSnapshot = await getDocs(likesQuery);
            user.likesGiven = likesSnapshot.size;

            const commentsRef = collection(db, "comments");
            const commentsQuery = query(commentsRef, where("userId", "==", user.key));
            const commentsSnapshot = await getDocs(commentsQuery);
            user.commentsGiven = commentsSnapshot.size;

            user.cosmicShards = calculateCosmicShards(user);
        }));

        usersData.sort((a, b) => b.cosmicShards - a.cosmicShards);
        return usersData;
    };

    const fetchUserRank = async () => {
        const userToCheck = userId || currentUser?.uid;
        if (!userToCheck) return;

        setLoading(true);

        const cachedData = JSON.parse(localStorage.getItem(CACHE_KEY));
        if (cachedData && !isCacheExpired(cachedData.timestamp)) {
            const rankIndex = cachedData.usersData.findIndex(user => user.key === userToCheck);
            if (rankIndex !== -1) {
                setUserRank(rankIndex + 1);
                setCosmicShards(cachedData.usersData[rankIndex].cosmicShards);
                setLoading(false);
                return;
            }
        }

        const usersData = await fetchUserRankData();
        
        const rankIndex = usersData.findIndex(user => user.key === userToCheck);
        if (rankIndex !== -1) {
            setUserRank(rankIndex + 1);
            setCosmicShards(usersData[rankIndex].cosmicShards);
        }

        localStorage.setItem(
            CACHE_KEY,
            JSON.stringify({ usersData, timestamp: new Date().getTime() })
        );

        setLoading(false);
    };

    useEffect(() => {
        fetchUserRank();
    }, [currentUser, userId]);

    return { userRank, cosmicShards, loading };
}

export default useUserRank;
