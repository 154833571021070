// PushNotificationHandler.js
import React, { useEffect, useContext } from 'react';
import { PushNotifications } from '@capacitor/push-notifications';
import { doc, setDoc } from 'firebase/firestore';
import { db } from "./Firebase/firebaseConfig";
import UserContext from "./UserContext";
import { Capacitor } from '@capacitor/core';

const PushNotificationHandler = () => {
    const { currentUser } = useContext(UserContext);

    // Fonction pour enregistrer le token FCM dans Firestore
    const saveFcmToken = async (userId, token) => {
        try {
            await setDoc(doc(db, 'users', userId), { fcmToken: token }, { merge: true });
        } catch (error) {
            console.error('Erreur lors de l\'enregistrement du token FCM:', error);
        }
    };

    useEffect(() => {
        // Vérifier si l'application s'exécute sur une plateforme mobile
        if (Capacitor.isNativePlatform()) {
            PushNotifications.requestPermissions().then(result => {
                if (result.receive === 'granted') {
                    PushNotifications.register();
                } else {
                    console.log('Permission de notification refusée');
                }
            });

            const handleRegistration = async (token) => {
                if (token && token.value) {
                    console.log('Token FCM reçu :', token.value);
                    if (currentUser) {
                        await saveFcmToken(currentUser.uid, token.value);
                    }
                } else {
                    console.log('Erreur : le token FCM est null ou indéfini');
                }
            };

            PushNotifications.addListener('registration', handleRegistration);
            PushNotifications.addListener('registrationError', (error) => {
                console.error('Erreur d\'enregistrement :', error);
            });

            // Nettoyage de l'effet
            return () => {
                PushNotifications.removeAllListeners();
            };
        } else {
            console.log('L\'application ne s\'exécute pas sur une plateforme mobile');
        }
    }, [currentUser]);

    return null; // Ce composant n'affiche rien
};

export default PushNotificationHandler;
