// Home.js
import React, { useState, useEffect } from "react";
import FollowedFeed from "./FollowedFeed";
import { Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Button } from "@nextui-org/react";
import { ArrowDown2 } from "iconsax-react";
import Feed from './Feed'
import { useTranslation } from 'react-i18next';

function Home({ overflowDivRef, userRank }) {
    const { t } = useTranslation();
    // Initialize the state from localStorage or default to "new"
    const [selectedFeed, setSelectedFeed] = useState(() => {
        return localStorage.getItem("selectedFeed") || "new";
    });

    // Function to handle feed selection
    const handleFeedChange = (key) => {
        setSelectedFeed(key);
        localStorage.setItem("selectedFeed", key); // Save the selection to localStorage
    };

    // Optional: Use useEffect to save selectedFeed to localStorage whenever it changes
    useEffect(() => {
        localStorage.setItem("selectedFeed", selectedFeed);
    }, [selectedFeed]);

    return (
        <div className="overflow-auto flex flex-col min-h-screen">

      
            <div className="flex flex-row justify-between items-start">

                <h1 className="font-bold text-3xl">
                    {t("explore")}
                </h1>

                <Dropdown className="dark">
                    <DropdownTrigger>
                        <Button variant="light">
                            {selectedFeed === "followed" ? t("following") : t("new")}
                            <ArrowDown2 size={16} />
                        </Button>
                    </DropdownTrigger>
                    <DropdownMenu aria-label="Feed Selector" onAction={handleFeedChange}>
                        <DropdownItem key="followed">{t("following")}</DropdownItem>
                        <DropdownItem key="general"> {t("new")}</DropdownItem>
                    </DropdownMenu>
                </Dropdown>

            </div>


            {selectedFeed === "followed" ? <FollowedFeed overflowDivRef={overflowDivRef} /> : <Feed overflowDivRef={overflowDivRef} />}
        </div>
    );
}

export default Home;
