import React, { useState, useContext, useEffect } from "react";
import { collection, addDoc, query, where, onSnapshot, doc, deleteDoc, updateDoc, arrayUnion, arrayRemove } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db } from "./Firebase/firebaseConfig";
import UserContext from "../components/UserContext";
import { Button, Textarea, Link } from "@nextui-org/react";
import { Send, Heart } from "iconsax-react";
import { formatDistanceToNow } from 'date-fns';
import { addCommentNotification, addReplyNotification, addLikeNotification } from "./Firebase/addLikeNotification";
import { checkProfanity } from './PerspectiveService';
import { useTranslation } from 'react-i18next';

const formatTime = (date) => {
    if (!date || isNaN(date)) return "";
    let result = formatDistanceToNow(date, { addSuffix: false });
    result = result.replace(/about (\d+) hours?/, '$1 h').replace(/(\d+) minutes?/, '$1 min');
    return result;
};

const ImageWithComments = ({ imageId, imageOwnerId, inputRef }) => {
    const { currentUser } = useContext(UserContext);
    const [comment, setComment] = useState("");
    const [comments, setComments] = useState([]);
    const [replies, setReplies] = useState([]);
    const [reply, setReply] = useState({});
    const [showReply, setShowReply] = useState(null);
    const [showAllComments, setShowAllComments] = useState(false);
    const [image, setImage] = useState(null);
    const { t } = useTranslation();

    // Charger les commentaires de l'image
    useEffect(() => {
        if (!imageId) return;
        const q = query(collection(db, "comments"), where("imageId", "==", imageId));
        const unsubscribe = onSnapshot(q, (snapshot) => {
            const commentsList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data(), likes: doc.data().likes || [] }));
            commentsList.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);
            setComments(commentsList);
        });
        return () => unsubscribe();
    }, [imageId]);

    // Charger toutes les réponses
    useEffect(() => {
        const qReplies = query(collection(db, "replies"));
        const unsubscribeReplies = onSnapshot(qReplies, (snapshot) => {
            const repliesList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data(), likes: doc.data().likes || [] }));
            setReplies(repliesList);
        });
        return () => unsubscribeReplies();
    }, []);

    const handleCommentSubmit = async (e) => {
        e.preventDefault();
        if (!currentUser) return alert("Please log in to comment.");
        if (!comment.trim() && !image) return alert("Comment or image cannot be empty.");

        const isProfane = await checkProfanity(comment);
        if (isProfane) return alert("Your comment contains inappropriate language.");

        let imageUrl = null;

        // Upload the image if there is one
        if (image) {
            const storage = getStorage(); // Initialize Firebase Storage
            const storageRef = ref(storage, `comments/${currentUser.uid}/${Date.now()}_${image.name}`);
            await uploadBytes(storageRef, image);
            imageUrl = await getDownloadURL(storageRef);
        }

        const newComment = {
            userId: currentUser.uid,
            imageId,
            comment,
            imageUrl,  // Add the image URL if available
            userName: currentUser.username || currentUser.email,
            createdAt: new Date(),
        };
        await addDoc(collection(db, "comments"), newComment);
        setComment("");
        setImage(null);  // Reset image state
        await addCommentNotification(currentUser.username || currentUser.email, currentUser.uid, imageId, imageOwnerId, comment);
    };


    // Gérer l'envoi de réponse
    const handleReplySubmit = async (commentId, replyText, commentOwnerId) => {
        if (!currentUser) return alert("Please log in to reply.");
        if (!replyText.trim()) return alert("Reply cannot be empty.");

        const isProfane = await checkProfanity(replyText);
        if (isProfane) return alert("Your reply contains inappropriate language.");

        const newReply = {
            userId: currentUser.uid,
            commentId,
            reply: replyText,
            userName: currentUser.username || currentUser.email,
            createdAt: new Date(),
        };
        await addDoc(collection(db, "replies"), newReply);
        setReply((prev) => ({ ...prev, [commentId]: "" }));
        setShowReply(null);
        await addReplyNotification(currentUser.username || currentUser.email, currentUser.uid, imageId, commentId, commentOwnerId, replyText);
    };


    // Gérer le like sur un commentaire
    const handleLikeComment = async (commentId) => {
        if (!currentUser) return alert("Please log in to like a comment.");

        const commentRef = doc(db, "comments", commentId);
        const comment = comments.find((c) => c.id === commentId);

        if (comment && comment.likes && comment.likes.includes(currentUser.uid)) {
            // Si déjà liké, retirer le like
            await updateDoc(commentRef, {
                likes: arrayRemove(currentUser.uid),
            });
        } else {
            // Sinon, ajouter un like
            await updateDoc(commentRef, {
                likes: arrayUnion(currentUser.uid),
            });
            // Envoyer une notification spécifique pour un like de commentaire
            await addLikeNotification(
                currentUser.username || currentUser.email, // Nom ou email de l'utilisateur qui aime
                currentUser.uid,  // ID de l'utilisateur qui aime
                imageId,  // ID de l'image associée
                comment.userId,  // ID du propriétaire du commentaire
                true  // isComment = true, pour indiquer qu'il s'agit d'un like sur un commentaire
            );
        }
    };


    // Gérer le like sur une réponse
    const handleLikeReply = async (replyId) => {
        if (!currentUser) return alert("Please log in to like a reply.");

        const replyRef = doc(db, "replies", replyId);
        const reply = replies.find((r) => r.id === replyId);
        if (reply && reply.likes && reply.likes.includes(currentUser.uid)) {
            // Si déjà liké, retirer le like
            await updateDoc(replyRef, {
                likes: arrayRemove(currentUser.uid),
            });
        } else {
            // Sinon, ajouter un like
            await updateDoc(replyRef, {
                likes: arrayUnion(currentUser.uid),
            });
            await addLikeNotification(currentUser.username || currentUser.email, currentUser.uid, imageId, reply.userId, false, true);  // isReply = true
        }
    };



    // Supprimer un commentaire
    const handleDelete = async (commentId) => {
        await deleteDoc(doc(db, "comments", commentId));
    };

    // Supprimer une réponse
    const handleDeleteReply = async (replyId) => {
        await deleteDoc(doc(db, "replies", replyId));
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImage(file);
        }
    };

    return (
        <div>
            {/* Formulaire d'envoi de commentaire */}
            <form className="flex items-stretch h-[40px]" onSubmit={handleCommentSubmit}>
                <Textarea
                    className="flex-grow mr-2 h-[40px]"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    placeholder={t("writecomment")}
                    variant="underlined"
                    size="sm"
                    ref={inputRef}
                />
          
                <Button isIconOnly size="sm" className="flex-shrink-0 h-auto m-0" variant="Light" color="primary" type="submit">
                    <Send size="18" variant="Bold" className="svg-primary-200" />
                </Button>
            </form>


            {/* Affichage des commentaires */}
            {(showAllComments ? comments : comments.slice(0, 2)).map((c) => (
                <div key={c.id} className="mt-5">
                    <div className=" bg-default-50 p-3 rounded-md flex gap-1 flex-col" >
                        <div className="flex flex-col justify-between gap-1 " >
                            <div className="flex items-center gap-2">
                                <Link href={`/profile/${c.userName || "Unknown"}`} className="text-sm cursor-pointer hover:underline text-default-700 font-medium">
                                    {c.userName || "Unknown"}
                                </Link>
                                <p className="text-xs text-default-500">{formatTime(new Date(c.createdAt.seconds * 1000))}</p>
                            </div>



                            <div className="flex items-center justify-between w-full gap-4" >
                                <p className="text-default-600">{c.comment}</p>
                                {currentUser && (
                                    <Link className="dark text-sm text-default-500  cursor-pointer gap-1" onClick={() => handleLikeComment(c.id)}>
                                        {c.likes && c.likes.includes(currentUser.uid) ? (
                                            <Heart size="18" style={{ color: 'hsl(var(--nextui-danger-400))' }} variant="Bold" />
                                        ) : (
                                            <Heart size="18" variant="Linear" />
                                        )}
                                        <p className="font-medium text-white"  >{c.likes && c.likes.length > 0 && ` ${c.likes.length}`}</p>
                                    </Link>
                                )}
                            </div>



                            <div className="flex gap-2 ">
                                {currentUser && currentUser.uid === c.userId && (
                                    <Link className="text-sm text-default-500 hover:underline cursor-pointer" onClick={() => handleDelete(c.id)}>
                                        Delete
                                    </Link>
                                )}
                                <Link className="text-sm text-default-500 hover:underline cursor-pointer" onClick={() => setShowReply(c.id === showReply ? null : c.id)}>
                                    Reply
                                </Link>
                            </div>




                        </div>

                    </div>

                    {/* Formulaire de réponse */}
                    {c.id === showReply && (
                        <div className="flex my-4 ml-16">
                            <Textarea
                                variant="underlined"
                                className="flex-grow mr-2"
                                value={reply[c.id] || ""}
                                onChange={(e) => setReply({ ...reply, [c.id]: e.target.value })}
                                placeholder="Write a reply..."
                                minRows={1}
                            />
                            <Button
                                className="flex-shrink-0 h-auto"
                                color="default"
                                variant="light"
                                isIconOnly
                                onClick={() => handleReplySubmit(c.id, reply[c.id], c.userId)}
                            >
                                <Send size="18" variant="Bold" />
                            </Button>
                        </div>
                    )}

                    {/* Affichage des réponses du commentaire */}
                    {replies
                        .filter(r => r.commentId === c.id) // Affiche toutes les réponses liées au commentaire
                        .sort((a, b) => a.createdAt.seconds - b.createdAt.seconds) // Trie les réponses de la plus ancienne à la plus récente


                        .map((r) => (
                            <div key={r.id} >


                                <div className="ml-16 mt-2 bg-default-50 p-3 rounded-md flex gap-1 flex-col" >
                                    <div className="flex flex-col justify-between gap-1 " >
                                        <div className="flex items-center gap-2">
                                            <Link href={`/profile/${r.userName || "Unknown"}`} className="text-sm cursor-pointer hover:underline text-default-700 font-medium">
                                                {r.userName || "Unknown"}
                                            </Link>
                                            <p className="text-xs text-default-500">{formatTime(new Date(r.createdAt.seconds * 1000))}</p>
                                        </div>



                                        <div className="flex items-center justify-between w-full gap-4" >
                                            <p className="text-default-600">{r.reply}</p>

                                            {currentUser && (
                                                <Link className="flex  dark text-sm text-default-500  cursor-pointer gap-1" onClick={() => handleLikeReply(r.id)}>
                                                    {r.likes && r.likes.includes(currentUser.uid) ? (
                                                        <Heart size="18" style={{ color: 'hsl(var(--nextui-danger-400))' }} variant="Bold" />
                                                    ) : (
                                                        <Heart size="18" variant="linear" />
                                                    )}
                                                    <p className="font-medium text-white" >{r.likes && r.likes.length > 0 && ` ${r.likes.length}`}</p>
                                                </Link>
                                            )}
                                        </div>

                                        <div className="flex gap-2 ">

                                            {currentUser && currentUser.uid === r.userId && (
                                                <Link className="text-sm text-default-500 hover:underline cursor-pointer" onClick={() => handleDeleteReply(r.id)}>
                                                    Delete
                                                </Link>
                                            )}
                                            <Link className="text-sm text-default-500 hover:underline cursor-pointer" onClick={() => setShowReply(r.id === showReply ? null : r.id)}>
                                                Reply
                                            </Link>
                                        </div>
                                    </div>
                                </div>


                                {r.id === showReply && (
                                    <div className="flex my-2 ml-16">
                                        <Textarea
                                            variant="underlined"
                                            className="flex-grow mr-2"
                                            value={reply[r.id] || ""}
                                            onChange={(e) => setReply({ ...reply, [r.id]: e.target.value })}
                                            placeholder="Reply to this reply..."
                                            minRows={1}
                                        />
                                        <Button
                                            className="flex-shrink-0 h-auto"
                                            color="default"
                                            variant="light"
                                            isIconOnly
                                            onClick={() => handleReplySubmit(r.commentId, reply[r.id], r.userId)}
                                        >
                                            <Send size="18" variant="Bold" />
                                        </Button>
                                    </div>
                                )}
                            </div>
                        ))}
                </div>
            ))}

       
            {!showAllComments && comments.length > 2 && (
                <div className="mt-2">
                    <Link className="text-sm text-default-500 hover:underline cursor-pointer" onClick={() => setShowAllComments(true)}>
                        Show all comments
                    </Link>
                </div>
            )}
        </div>
    );
};

export default ImageWithComments;
