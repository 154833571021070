import React, { createContext, useState } from 'react';

const SearchContext = createContext();

export const SearchProvider = ({ children }) => {
    const [allData, setAllData] = useState([]);
    const [results, setResults] = useState([]);
    const [resultsCount, setResultsCount] = useState(0);
    const [selectedTags, setSelectedTags] = useState([]);
    const [searchQuery, setSearchQuery] = useState(''); // Pour gérer la recherche manuelle

    // Définir la fonction resetSearch pour réinitialiser les états de recherche
    const resetSearch = () => {
        setSearchQuery('');
        setSelectedTags([]);
        setResults([]);
        setResultsCount(0);
    };

    return (
        <SearchContext.Provider
            value={{
                allData,
                setAllData,
                results,
                setResults,
                resultsCount,
                setResultsCount,
                selectedTags,
                setSelectedTags,
                searchQuery,
                setSearchQuery,
                resetSearch, // Ajoutez ici resetSearch
            }}
        >
            {children}
        </SearchContext.Provider>
    );
};

export default SearchContext;
